import { useEffect, useState } from "react";
import { db } from "../../firebase-config";

function FemaleCount() {
  // if (isLoading) {
  //   return <div class="ui active centered inline loader"></div>;
  // }

  // if (error) {
  //   return <p>{error}</p>;
  // }

  const [femalecount, setFeMaleCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Reference to the "members" collection
        const membersRef = db.collection("members");

        // Query to get documents where "field" is equal to "Editorial"
        const querySnapshot = await membersRef
          .where("gender", "==", "Female")
          .get();

        // Get the count of documents in the query snapshot
        const count = querySnapshot.size;

        setFeMaleCount(count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div>{femalecount}</div>
    </>
  );
}
export default FemaleCount;
