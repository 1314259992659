import React, { useState, useEffect } from "react";
import { Icon, Button, Form } from "semantic-ui-react";
import { db } from "../../firebase-config";
import ModalEdit from "./ModalEdit";
import { Table, Alert } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import GoToTop from "../../GoToTop";
import { doc, serverTimestamp, getDoc, onSnapshot } from "firebase/firestore";
import SuccessModal from "../SuccessModal";
import { update } from "firebase/database";

const initialState = {
  name: "",
  regno: "",
  email: "",
  contact: "",
  dept: "",
  status: "",
};

const TaskDetails = () => {
  const [data, setData] = useState(initialState);
  const {
    name,
    photo,
    regno,

    contact,
    dept,

    status,
  } = data;
  const [progress, setProgress] = useState(null);
  const [errors, setErrors] = useState({});

  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    id && getSinglePhoto();
  }, [id]);

  const getSinglePhoto = async () => {
    const docRef = doc(db, "TedXtasks", id);
    const snapshot = await getDoc(docRef, "TedXtasks");
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const [task, setTask] = useState("");
  const [lastdate, setLastDate] = useState("");
  const [loader, setLoader] = useState("");
  const [success, setsuccessLoader] = useState("");

  const today = new Date();
  const chattime =
    today.getHours().toString().padStart(2, "0") +
    ":" +
    today.getMinutes().toString().padStart(2, "0") +
    ":" +
    today.getSeconds().toString().padStart(2, "0");
  const current = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };

  const chatdate = current.toLocaleString("en-US", options);

  const formattedDateTime = `${chatdate}`;

  const handleSubmit = async () => {
    try {
      setLoader("Adding Task ! Please wait...");

      setsuccessLoader("");
      db.collection("TedXtasks")
        .doc(id)
        .collection("Tasks")
        .doc(`${chatdate}-${chattime}`)
        .set({
          task,
          lastdate,
          name,
          dept,
          userID: id,
          postedOn: serverTimestamp(),
          id: `${chatdate}-${chattime}`,
          tstatus: "Assigned",
        })
        .then(() => {
          db.collection("TedXtasks").doc(id).update({
            task,
            lastdate,
            postedOn: serverTimestamp(),
            tstatus: "Assigned",
          });
        });

      // Reset form fields and close the modal
      // Reset form fields and close the modal
      setLoader("");
      setsuccessLoader("Task added successfully!");
      setTask("");
      setLastDate("");

      setTimeout(() => {
        setLoader("");
        setsuccessLoader("");
      }, 2000);
    } catch (error) {
      console.log("Error submitting task:", error);
    }
  };

  const [openE, setOpenE] = useState(false);
  const [modaledit, setModalEdit] = useState({});
  const handleEdit = (item) => {
    setOpenE(true);
    setModalEdit(item);
  };

  const [availP, setAvail] = useState([]);
  // getting Available projects from firestore collection and updating the state
  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("TedXtasks").doc(id).collection("Tasks").orderBy("id"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setAvail(list);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const formatTimestamp = (updatedOn) => {
    const date = updatedOn.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (postedOn) => {
    const date = postedOn.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <>
      <GoToTop />
      <main id="main" class="main">
        <div class="main-body">
          <div class="row gutters-sm">
            <div class="col-md-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center text-center my-2">
                    <img
                      src={photo}
                      alt="Profile"
                      style={{
                        width: "120px",
                        height: "130px",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />

                    <div class="mt-3">
                      <div style={{ marginTop: "-28px" }}>
                        <span class="ui green center label">{status}</span>
                      </div>
                      <h3
                        style={{
                          marginTop: "10px",
                          textTransform: "uppercase",
                        }}
                      >
                        {name}
                      </h3>

                      <h6>
                        <span style={{ color: "grey" }}>Contact:</span>{" "}
                        <i class="fa fa-phone"></i>
                        <b> {contact}</b>
                      </h6>
                      <p
                        style={{
                          color: "#000000",
                          fontWeight: "bold",
                          marginTop: "15px",
                          padding: "5px 1px 5px 1px",
                          borderRadius: "5px",
                          backgroundColor: "#e3e3e3",
                        }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-9">
              <div class="card mb-3">
                <div class="card-body">
                  <Form onSubmit={handleSubmit}>
                    <h4
                      style={{
                        backgroundColor: "#212A3E",
                        color: "white",
                        maxWidth: "25vh",
                        padding: "7px",
                        marginTop: "10px",
                      }}
                    >
                      ADD TASK:
                    </h4>
                    {loader && (
                      <Alert
                        variant="warning"
                        style={{ fontSize: "11px", textAlign: "center" }}
                      >
                        {loader}
                      </Alert>
                    )}
                    {success && (
                      <Alert
                        variant="success"
                        style={{ fontSize: "11px", textAlign: "center" }}
                      >
                        {success}
                      </Alert>
                    )}

                    <div className="row">
                      <div className="col-lg-8 my-2">
                        <Form.TextArea
                          required={true}
                          label="Task"
                          name="task"
                          placeholder="Write briefly about the task..."
                          onChange={(e) => setTask(e.target.value)}
                          value={task}
                        ></Form.TextArea>
                      </div>
                      <div className="col-lg-4 my-2">
                        <Form.Input
                          required={true}
                          type="date"
                          value={lastdate}
                          onChange={(e) => setLastDate(e.target.value)}
                          placeholder="Date of Submission"
                          label="Date of Submission"
                        />

                        {!lastdate || !task ? (
                          <>
                            <Button
                              color="blue"
                              size="small"
                              type="submit"
                              disabled
                              className="btn btn-primary"
                            >
                              Add task
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              color="blue"
                              size="small"
                              type="submit"
                              onClick={handleSubmit}
                              className="btn btn-primary"
                            >
                              Add task
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </div>

              <div class="card mb-3">
                <div class="card-body">
                  <h4
                    style={{
                      backgroundColor: "#212A3E",
                      color: "white",
                      maxWidth: "25vh",
                      padding: "7px",
                      marginTop: "10px",
                    }}
                  >
                    TASKS:
                  </h4>

                  <div className="row">
                    {availP.length > 0 ? (
                      <>
                        {availP.map((item, index) => (
                          <div
                            key={item.id}
                            className="card"
                            style={{
                              padding: "10px",
                              fontSize: "12px",
                              marginBottom: "7px",
                            }}
                          >
                            <div className="row">
                              <div className="col-lg-7">
                                <p>
                                  <span
                                    style={{
                                      color: "grey",
                                      fontStyle: "italic",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    Task {index + 1}:{" "}
                                  </span>
                                  <b>{item.task}</b>
                                </p>

                                <p>
                                  <span
                                    style={{
                                      color: "grey",
                                      fontStyle: "italic",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    Remark :{" "}
                                  </span>
                                  {item.atten}
                                </p>
                              </div>
                              <div
                                className="col-lg-4"
                                style={{
                                  borderLeft: "1px solid grey",
                                }}
                              >
                                <i style={{ color: "grey" }}>
                                  Submission Date:{" "}
                                </i>{" "}
                                <b style={{ color: "red" }}>{item.lastdate}</b>
                                <br />
                                <i style={{ color: "grey" }}>
                                  Assigned On:{" "}
                                </i>{" "}
                                <b style={{ color: "grey" }}>
                                  {" "}
                                  {item.postedOn &&
                                    formatTimestamp1(item.postedOn)}
                                </b>
                                <br />
                                <i style={{ color: "grey" }}>Status: </i>{" "}
                                <b
                                  style={{
                                    color:
                                      item.tstatus === "Assigned"
                                        ? "orange"
                                        : item.tstatus === "Unassigned"
                                        ? "red"
                                        : item.tstatus === "Completed"
                                        ? "green"
                                        : "yellow",
                                  }}
                                >
                                  {item.tstatus}
                                </b>
                                {item.updatedOn !== null &&
                                item.updatedOn !== undefined ? (
                                  <>
                                    {" "}
                                    <br />
                                    <i style={{ color: "grey" }}>
                                      Updated On:{" "}
                                    </i>{" "}
                                    <b style={{ color: "grey" }}>
                                      {item.updatedOn &&
                                        formatTimestamp(item.updatedOn)}
                                    </b>
                                  </>
                                ) : null}
                              </div>
                              <div
                                className="col-lg-1"
                                style={{
                                  borderLeft: "1px solid grey",
                                  textAlign: "center",
                                  fontSize: "20px",
                                }}
                              >
                                <i
                                  onClick={() => handleEdit(item)}
                                  style={{
                                    cursor: "pointer",
                                    color: "orange",
                                    marginTop: "20px",
                                  }}
                                  class="fa fa-pencil"
                                ></i>
                                {openE && (
                                  <ModalEdit
                                    open={openE}
                                    setOpen={setOpenE}
                                    userId={id}
                                    // handleDelete={handleDelete}
                                    {...modaledit}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div
                        colSpan="9"
                        style={{ textAlign: "center", color: "red" }}
                      >
                        No data found
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br> <br></br> <br></br>
        <SuccessModal isOpen={isSubmitted} />
      </main>
    </>
  );
};

export default TaskDetails;
