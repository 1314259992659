import React, { useState, useEffect } from "react";
import { db, auth } from "../firebase-config";

import { Link, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState("");
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  useEffect(() => {
    const fetchUserData = async () => {
      if (userId) {
        try {
          const userDocRef = db.collection("members").doc(userId);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            setUserData(userData);
          } else {
            navigate("/");
          }
        } catch (error) {
          console.log("Error getting user document:", error);
        }
      }
    };

    // Call the fetchUserData function
    fetchUserData();
  }, [userId]);
  return (
    <div>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link className="nav-link collapsed" to="/">
              <i className="fa fa-home"></i>
              <span>Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <i className="fa fa-users"></i>
              <span>Members</span>
            </a>

            <div class="collapse" id="collapseExample">
              <div class="card">
                <li>
                  <Link
                    style={{ fontSize: "11px" }}
                    className="nav-link collapsed"
                    to="/www_members_details_"
                  >
                    <i className="fa fa-circle-o"></i>
                    <span>Members List</span>
                  </Link>
                </li>

                <li style={{ marginTop: "-7px" }}>
                  <Link
                    style={{ fontSize: "11px" }}
                    className="nav-link collapsed"
                    to="/www_members_agreements_"
                  >
                    <i className="fa fa-circle-o"></i>
                    <span>Members Agreement</span>
                  </Link>
                </li>
              </div>
            </div>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/www_members_attendance_">
              <i className="fa fa-calendar"></i>
              <span>Attendance</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/www_recruitment_">
              <i className="fa fa-handshake-o"></i>
              <span>Recruitment</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/www_leaves_">
              <i className="fa fa-paper-plane"></i>
              <span>Leave Requests</span>
            </Link>
          </li>

          <li className="nav-item">
            <a
              className="nav-link collapsed"
              data-bs-toggle="collapse"
              href="#collapseExample2"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample2"
            >
              <i className="fa fa-users"></i>
              <span>Offline Events</span>
            </a>

            <div class="collapse" id="collapseExample2">
              <div class="card">
                <li>
                  <Link
                    style={{ fontSize: "11px" }}
                    className="nav-link collapsed"
                    to="/www_events_"
                  >
                    <i className="fa fa-circle-o"></i>
                    <span>Event Registration</span>
                  </Link>
                </li>

                <li style={{ marginTop: "-7px" }}>
                  <Link
                    style={{ fontSize: "11px" }}
                    className="nav-link collapsed"
                    to="/events_feedbacks"
                  >
                    <i className="fa fa-circle-o"></i>
                    <span>Event Feedbacks</span>
                  </Link>
                </li>
              </div>
            </div>
          </li>

          <li className="nav-item">
            <Link className="nav-link collapsed" to="/www_finance_">
              <i className="fa fa-usd"></i>
              <span>Finance</span>
            </Link>
          </li>

          <li
            className="nav-item"
            style={{
              marginTop: "1vh",
              borderRadius: "5px",
              backgroundColor: "#fff",
              color: "white",
            }}
          >
            <Link
              className="nav-link collapsed"
              style={{ backgroundColor: "#f73f31" }}
              to="/tedxtasks"
            >
              <span style={{ color: "white" }}>TEDx</span>
            </Link>
          </li>

          <li
            className="nav-item"
            style={{
              marginTop: "1vh",
              borderRadius: "5px",
              backgroundColor: "#ff7a7a",
              color: "white",
            }}
          >
            <Link
              className="nav-link collapsed"
              style={{ backgroundColor: "#1f88be" }}
              to="/myfiles_"
            >
              <i
                style={{ color: "white" }}
                class="fa fa-file-text"
                aria-hidden="true"
              ></i>
              <span style={{ color: "white" }}>My Documents</span>
            </Link>
          </li>

          <li
            className="nav-item my-5"
            style={{ position: "absolute", bottom: "-20px", fontSize: "11px" }}
          >
            <div>
              <i style={{ color: "grey" }}>
                Version: <b>v2.06.05</b>
              </i>
              <p>
                Copyright <i class="fa fa-copyright" aria-hidden="true"></i>{" "}
                WakeWithWellness
              </p>
            </div>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
