import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase-config";
import { onSnapshot } from "firebase/firestore";

import { Table } from "react-bootstrap";
import GoToTop from "../../GoToTop";

function Atten() {
  const [photos, setPhotos] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("attendance").orderBy("regno"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setPhotos(list);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();
  const currentMonthYear = `${month}${year}`;
  const currentMonth = `${month} ${year}`;

  currentDate.setMonth(currentDate.getMonth() - 1); // Set the month to the previous month

  const pmonth = currentDate.toLocaleString("default", { month: "long" });
  const previousMonth = `${pmonth} ${year}`;
  const previousMonthYear = `${pmonth}${year}`;
  return (
    <>
      <GoToTop />
      <main id="main" class="main">
        <div className="section-title">
          <h2
            style={{
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              color: "#212A3E",
            }}
          >
            Member's Attendance
          </h2>
        </div>
        <br></br>
        {/* Table */}

        <Table responsive striped bordered hover size="sm">
          <thead>
            <tr
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                backgroundColor: "#1f88be",
                color: "white",
              }}
            >
              <th style={{ textAlign: "center" }}>#</th>

              <th style={{ textAlign: "left" }}>Reg No.</th>
              <th style={{ textAlign: "left" }}>Name</th>
              <th style={{ textAlign: "center" }}>{previousMonth} A</th>
              <th style={{ textAlign: "center" }}>{previousMonth} B</th>
              <th style={{ textAlign: "center" }}>{currentMonth} A</th>
              <th style={{ textAlign: "center" }}>{currentMonth} B</th>
              <th style={{ textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "12px" }}>
            {photos &&
              photos.map((item, index) => (
                <tr>
                  <td style={{ textAlign: "center", width: "5vh" }}>
                    {index + 1}
                  </td>

                  <td
                    style={{
                      textAlign: "left",
                      textTransform: "uppercase",
                      width: "13vh",
                    }}
                  >
                    {item.regno}
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      width: "32vh",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {item.name}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      width: "5vh",
                      color:
                        item[previousMonthYear + "A"] === "Present"
                          ? "green"
                          : item[previousMonthYear + "A"] === "Absent"
                          ? "red"
                          : "orange",
                    }}
                  >
                    {item[previousMonthYear + "A"]}
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      width: "5vh",
                      color:
                        item[previousMonthYear + "B"] === "Present"
                          ? "green"
                          : item[previousMonthYear + "B"] === "Absent"
                          ? "red"
                          : "orange",
                    }}
                  >
                    {item[previousMonthYear + "B"]}
                  </td>

                  <td
                    style={{
                      textAlign: "center",
                      width: "5vh",
                      fontWeight: "500",
                      color:
                        item[currentMonthYear + "A"] === "Present"
                          ? "green"
                          : item[currentMonthYear + "A"] === "Absent"
                          ? "red"
                          : "orange",
                    }}
                  >
                    {item[currentMonthYear + "A"]}
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      width: "5vh",
                      color:
                        item[currentMonthYear + "B"] === "Present"
                          ? "green"
                          : item[currentMonthYear + "B"] === "Absent"
                          ? "red"
                          : "orange",
                    }}
                  >
                    {item[currentMonthYear + "B"]}
                  </td>
                  <td style={{ textAlign: "center", width: "2vh" }}>
                    <Button
                      style={{ marginTop: "2px" }}
                      size="small"
                      onClick={() => navigate(`/editatten/${item.id}`)}
                    >
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        {/* Table */}

        <br></br>
      </main>
    </>
  );
}

export default Atten;
