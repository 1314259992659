import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar";

function Header2() {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setDate(new Date()), 1000);

    return function cleanup() {
      clearInterval(timer);
    };
  });

  const currentTime = new Date();
  const options = { month: "long", year: "numeric" };
  const currentMonthYear = currentTime.toLocaleString("default", options);
  const day = String(currentTime.getDate());
  const hours = String(currentTime.getHours()).padStart(2, "0");
  const minutes = String(currentTime.getMinutes()).padStart(2, "0");
  const seconds = String(currentTime.getSeconds()).padStart(2, "0");
  const formattedDateTime = `${day} ${currentMonthYear}; ${hours}:${minutes}:${seconds}`;

  return (
    <>
      <header id="header" class="header fixed-top d-flex align-items-center">
        <div class="d-flex align-items-center justify-content-between">
          <a class="logo d-flex align-items-center">
            {/* <img src="assets/img/logo.png" alt="" /> */}
            <span class="d-none d-lg-block">ADMIN PORTAL</span>
            {/* <i
              style={{
                marginLeft: "-10px",
                fontSize: "19px",
                marginTop: "-2px",
              }}
              class="fa fa-bars toggle-sidebar-btn"
              onClick={handleToggleClick}
            ></i> */}

            <span
              class="d-lg-none"
              style={{ fontSize: "18px" }}
              // style={{ fontSize: "18px", marginLeft: "8px" }}
            >
              ADMIN PORTAL
            </span>
          </a>
        </div>

        <div class="search-bar">
          <form class="search-form d-flex align-items-center">
            <input
              type="text"
              readOnly
              name="query"
              value={formattedDateTime}
            />
          </form>
        </div>
      </header>
    </>
  );
}

export default Header2;
