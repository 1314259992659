import { useEffect, useState } from "react";
import { db } from "../../firebase-config";
import BarGraph from "./BarGraph";

function StateCount({ collectionName, fieldName }) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [countData, setCountData] = useState({});

  useEffect(() => {
    const membersRef = db.collection("members").orderBy("state");

    Promise.all([membersRef.get()])
      .then((snapshots) => {
        const retrievedData = [];
        const count = {};

        snapshots.forEach((snapshot) => {
          snapshot.forEach((doc) => {
            const docData = doc.data();
            retrievedData.push(docData);
            const fieldValue = docData["state"] || docData["state"];
            if (count[fieldValue]) {
              count[fieldValue]++;
            } else {
              count[fieldValue] = 1;
            }
          });
        });

        setData(retrievedData);
        setCountData(count);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [collectionName, fieldName]);

  if (isLoading) {
    return <div class="ui active centered inline loader"></div>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="container-fluid">
      <BarGraph countData={countData} />
    </div>
  );
}

export default StateCount;
