import React, { useState, useEffect } from "react";
import { Loader, Icon, Button, Form } from "semantic-ui-react";
import { Table } from "react-bootstrap";
import { db } from "../../firebase-config";
import { useParams, useNavigate } from "react-router-dom";
import GoToTop from "../../GoToTop";
import { addDoc, updateDoc, doc, collection, getDoc } from "firebase/firestore";

const initialState = {
  name: "",
  email: "",
  phone: "",
  whatsapp: "",
  altno: "",
  college: "",
  department: "",
  department2: "",
  blood: "",
  date: "",
  status: "",
};

const EditMAgreement = () => {
  const [data, setData] = useState(initialState);
  const {
    photo,
    idcard,
    name,
    email,
    phone,
    whatsapp,
    altno,
    college,
    department,
    department2,
    blood,
    date,
    status,
    validity,
    y1,
    y2,
    c1,
    c2,
    r1,
    r2,
  } = data;
  const [progress, setProgress] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    id && getSinglePhoto();
  }, [id]);

  const getSinglePhoto = async () => {
    const docRef = doc(db, "MembersAgreement", id);
    const snapshot = await getDoc(docRef, "MembersAgreement");
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};
    if (!name) {
      errors.name = "Name is required";
    }

    if (!department) {
      errors.department = "Department is required";
    }

    if (!status) {
      errors.status = "Status is required";
    }

    if (!date) {
      errors.date = "Date is required";
    }

    if (!validity) {
      errors.validity = "Validity is required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (Object.keys(errors).length) return setErrors(errors);
    setIsSubmit(true);
    if (!id) {
      try {
        await addDoc(collection(db, "MembersAgreement"), {
          ...data,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await updateDoc(doc(db, "MembersAgreement", id), {
          ...data,
          id,
        });
      } catch (error) {
        console.log(error);
      }
    }

    navigate(-1);
  };

  return (
    <>
      <GoToTop />
      <main id="main" class="main">
        <div className="row">
          <div className="col-lg-2">
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-column align-items-center text-center">
                  <img
                    src={photo}
                    alt="Profile"
                    style={{
                      width: "20vh",
                      height: "25vh",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                  />

                  <div class="mt-3">
                    <h3>{name}</h3>
                    <p style={{ fontSize: "10px", marginTop: "-14px" }}>
                      <b>ID:</b> {id}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-column align-items-center text-center">
                  <img
                    src={idcard}
                    alt="ID Card"
                    style={{
                      width: "100%",
                      height: "auto",

                      borderRadius: "5px",
                    }}
                  />

                  <div class="mt-3">
                    <h3>{validity}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-10">
            <div
              className="card"
              style={{ backgroundColor: "white", padding: "15px" }}
            >
              <h2 style={{ color: "#212A3E" }}>Update Agreement Details</h2>

              <div className="row">
                <div className="col-lg">
                  {isSubmit ? (
                    <Loader active inline="centered" size="huge" />
                  ) : (
                    <>
                      <Form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-3 my-2">
                            <Form.Input
                              error={
                                errors.name ? { content: errors.name } : null
                              }
                              label="Name"
                              name="name"
                              placeholder="Name"
                              onChange={handleChange}
                              value={name}
                            />
                          </div>

                          <div className="col-lg-3 my-2">
                            <Form.Input
                              label="Email"
                              name="email"
                              placeholder="Email"
                              onChange={handleChange}
                              value={email}
                            />
                          </div>

                          <div className="col-lg-3 my-2">
                            <Form.Input
                              label="Contact"
                              name="phone"
                              placeholder="Contact no."
                              onChange={handleChange}
                              value={phone}
                            />
                          </div>

                          <div className="col-lg-3 my-2">
                            <Form.Input
                              label="Whatsapp"
                              name="whatsapp"
                              placeholder="Whatsapp no."
                              onChange={handleChange}
                              value={whatsapp}
                            />
                          </div>

                          <div className="col-lg-3 my-2">
                            <Form.Input
                              label="Alternate no."
                              name="altno"
                              placeholder="Alternate contact details"
                              onChange={handleChange}
                              value={altno}
                            />
                          </div>

                          <div className="col-lg-4 my-2">
                            <Form.Input
                              label="College"
                              name="college"
                              placeholder="College"
                              onChange={handleChange}
                              value={college}
                            />
                          </div>

                          <div className="col-lg-2 my-2">
                            <Form.Input
                              label="Blood"
                              name="blood"
                              placeholder="Blood"
                              onChange={handleChange}
                              value={blood}
                            />
                          </div>

                          <div className="col-lg-3 my-2">
                            <Form.Input
                              label="Department"
                              name="department"
                              placeholder="Department"
                              onChange={handleChange}
                              value={department}
                            />
                          </div>
                          <div className="col-lg-3 my-2">
                            <Form.Input
                              label="Department II"
                              name="department2"
                              placeholder="Department II"
                              onChange={handleChange}
                              value={department2}
                            />
                          </div>

                          <div className="col-lg-3 my-2">
                            <Form.Input
                              error={
                                errors.date ? { content: errors.date } : null
                              }
                              label="Joining Date"
                              name="date"
                              type="date"
                              placeholder="Joining Date"
                              onChange={handleChange}
                              value={date}
                            />
                          </div>

                          <div className="col-lg-3 my-2">
                            <Form.Field
                              error={
                                errors.status
                                  ? { content: errors.status }
                                  : null
                              }
                              label="Status"
                              style={{ height: "4.8vh" }}
                              name="status"
                              control="select"
                              value={status}
                              onChange={handleChange}
                            >
                              <option value="">Select status</option>

                              <option value="Approved">Approve</option>
                              <option value="Rejected">Reject</option>
                              <option value="Resigned">Resigned</option>
                            </Form.Field>
                          </div>

                          <div className="col-lg-3 my-2">
                            <Form.Field
                              error={
                                errors.validity
                                  ? { content: errors.validity }
                                  : null
                              }
                              label="Validity"
                              style={{ height: "4.8vh" }}
                              name="validity"
                              control="select"
                              value={validity}
                              onChange={handleChange}
                            >
                              <option disabled>--Select--</option>

                              <option value=" ">Active</option>
                              <option value="Expired">Expired</option>
                              <option value="Rejected">Rejected</option>
                            </Form.Field>
                          </div>

                          <div className="col-lg-6 my-2">
                            <Form.Input
                              label="Profile Photo"
                              name="photo"
                              type="text"
                              placeholder="Profile Photo"
                              onChange={handleChange}
                              value={photo}
                            />
                          </div>

                          <div className="col-lg-6 my-2">
                            <Form.Input
                              label="ID Card"
                              name="idcard"
                              type="text"
                              placeholder="ID Card"
                              onChange={handleChange}
                              value={idcard}
                            />
                          </div>
                        </div>

                        <h5 style={{ color: "red" }}>Tenure Certificates</h5>
                        <Table striped bordered responsive size="sm">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Year</th>
                              <th>Remark</th>
                              <th>Certificate</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td style={{ width: "10vh" }}>
                                <Form.Input
                                  name="y1"
                                  type="text"
                                  placeholder="Tenure Year"
                                  onChange={handleChange}
                                  value={y1}
                                />
                              </td>
                              <td>
                                <Form.Input
                                  name="r1"
                                  type="text"
                                  placeholder="Remark"
                                  onChange={handleChange}
                                  value={r1}
                                />
                              </td>
                              <td>
                                <Form.Input
                                  name="c1"
                                  type="text"
                                  placeholder="Certificate"
                                  onChange={handleChange}
                                  value={c1}
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>2</td>
                              <td style={{ width: "10vh" }}>
                                <Form.Input
                                  name="y2"
                                  type="text"
                                  placeholder="Tenure Year"
                                  onChange={handleChange}
                                  value={y2}
                                />
                              </td>
                              <td>
                                <Form.Input
                                  name="r2"
                                  type="text"
                                  placeholder="Remark"
                                  onChange={handleChange}
                                  value={r2}
                                />
                              </td>
                              <td>
                                <Form.Input
                                  name="c2"
                                  type="text"
                                  placeholder="Certificate"
                                  onChange={handleChange}
                                  value={c2}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>

                        <br></br>

                        <Button
                          style={{ float: "right" }}
                          color="green"
                          type="submit"
                          size="small"
                          icon
                          labelPosition="left"
                        >
                          <Icon name="check" /> Update
                        </Button>
                      </Form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default EditMAgreement;
