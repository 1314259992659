import { useEffect, useState } from "react";
import { db } from "../../firebase-config";

function Dept2Count() {
  // if (isLoading) {
  //   return <div class="ui active centered inline loader"></div>;
  // }

  // if (error) {
  //   return <p>{error}</p>;
  // }

  const [editorialCount, setEditorialCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Reference to the "members" collection
        const membersRef = db.collection("members");

        // Query to get documents where "field" is equal to "Editorial"
        const querySnapshot = await membersRef
          .where("department2", "==", "Editorial Department")
          .get();

        // Get the count of documents in the query snapshot
        const count = querySnapshot.size;

        setEditorialCount(count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //Curation Department

  const [curationCount, setCurationCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Reference to the "members" collection
        const membersRef = db.collection("members");

        // Query to get documents where "field" is equal to "Editorial"
        const querySnapshot = await membersRef
          .where("department2", "==", "Curation Department")
          .get();

        // Get the count of documents in the query snapshot
        const count = querySnapshot.size;

        setCurationCount(count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //Marketing Department

  const [marketingCount, setMarketingCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Reference to the "members" collection
        const membersRef = db.collection("members");

        // Query to get documents where "field" is equal to "Editorial"
        const querySnapshot = await membersRef
          .where("department2", "==", "Marketing Department")
          .get();

        // Get the count of documents in the query snapshot
        const count = querySnapshot.size;

        setMarketingCount(count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //PR Department

  const [prCount, setPrCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Reference to the "members" collection
        const membersRef = db.collection("members");

        // Query to get documents where "field" is equal to "Editorial"
        const querySnapshot = await membersRef
          .where("department2", "==", "PR Department")
          .get();

        // Get the count of documents in the query snapshot
        const count = querySnapshot.size;

        setPrCount(count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //Design Department

  const [designCount, setDesignCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Reference to the "members" collection
        const membersRef = db.collection("members");

        // Query to get documents where "field" is equal to "Editorial"
        const querySnapshot = await membersRef
          .where("department2", "==", "Design & Media Department")
          .get();

        // Get the count of documents in the query snapshot
        const count = querySnapshot.size;

        setDesignCount(count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //Events Department

  const [eventsCount, setEventsCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Reference to the "members" collection
        const membersRef = db.collection("members");

        // Query to get documents where "field" is equal to "Editorial"
        const querySnapshot = await membersRef
          .where("department2", "==", "Events Department")
          .get();

        // Get the count of documents in the query snapshot
        const count = querySnapshot.size;

        setEventsCount(count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  //None Department

  const [noneCount, setNoneCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Reference to the "members" collection
        const membersRef = db.collection("members");

        // Query to get documents where "field" is equal to "Editorial"
        const querySnapshot = await membersRef
          .where("department2", "==", "None")
          .get();

        // Get the count of documents in the query snapshot
        const count = querySnapshot.size;

        setNoneCount(count);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-3 my-1">
          <div
            className="ui card"
            style={{
              padding: "10px",
              width: "100%",
              backgroundColor: "#f0913e",
              color: "white",
            }}
          >
            <div className="row">
              <div className="col-10">Editorial Department</div>
              <div
                className="col-2"
                style={{ borderLeft: "1px solid #c6e7f7", textAlign: "center" }}
              >
                <h4>{editorialCount}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 my-1">
          <div
            className="ui card"
            style={{
              padding: "10px",
              width: "100%",
              backgroundColor: "#f0913e",
              color: "white",
            }}
          >
            <div className="row">
              <div className="col-10">Curation Department</div>
              <div
                className="col-2"
                style={{ borderLeft: "1px solid #c6e7f7", textAlign: "center" }}
              >
                <h4>{curationCount}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 my-1">
          <div
            className="ui card"
            style={{
              padding: "10px",
              width: "100%",
              backgroundColor: "#f0913e",
              color: "white",
            }}
          >
            <div className="row">
              <div className="col-10">Marketing Department</div>
              <div
                className="col-2"
                style={{ borderLeft: "1px solid #c6e7f7", textAlign: "center" }}
              >
                <h4>{marketingCount}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 my-1">
          <div
            className="ui card"
            style={{
              padding: "10px",
              width: "100%",
              backgroundColor: "#f0913e",
              color: "white",
            }}
          >
            <div className="row">
              <div className="col-10">PR Department</div>
              <div
                className="col-2"
                style={{ borderLeft: "1px solid #c6e7f7", textAlign: "center" }}
              >
                <h4>{prCount}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 my-1">
          <div
            className="ui card"
            style={{
              padding: "10px",
              width: "100%",
              backgroundColor: "#f0913e",
              color: "white",
            }}
          >
            <div className="row">
              <div className="col-10">Events Department</div>
              <div
                className="col-2"
                style={{ borderLeft: "1px solid #c6e7f7", textAlign: "center" }}
              >
                <h4>{eventsCount}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 my-1">
          <div
            className="ui card"
            style={{
              padding: "10px",
              width: "100%",
              backgroundColor: "#f0913e",
              color: "white",
            }}
          >
            <div className="row">
              <div className="col-10">Design & Media Department</div>
              <div
                className="col-2"
                style={{ borderLeft: "1px solid #c6e7f7", textAlign: "center" }}
              >
                <h4>{designCount}</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 my-1">
          <div
            className="ui card"
            style={{
              padding: "10px",
              width: "100%",
              backgroundColor: "#f0913e",
              color: "white",
            }}
          >
            <div className="row">
              <div className="col-10">None</div>
              <div
                className="col-2"
                style={{ borderLeft: "1px solid #c6e7f7", textAlign: "center" }}
              >
                <h4>{noneCount}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dept2Count;
