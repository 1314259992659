import React, { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { useNavigate, useLocation } from "react-router-dom";
import GoToTop from "../../GoToTop";
import { onSnapshot } from "firebase/firestore";
import { Table } from "react-bootstrap";
function EventFunds() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const type = params.get("type");
  const eventname = params.get("n");

  const [photos, setPhotos] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("Funds").doc(type).collection("Memb").orderBy("name"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setPhotos(list);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  const formatTimestamp = (date) => {
    const datee = date.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return datee.toLocaleString("en-US", options);
  };

  //////////////////////////////////////////////////
  const [totalDonation, setTotalDonation] = useState(0);

  useEffect(() => {
    const fetchTotalDonation = async () => {
      try {
        const donationsRef = db
          .collection("Funds")
          .doc(type)
          .collection("Memb");

        const donationsSnapshot = await donationsRef.get();

        let sum = 0;

        donationsSnapshot.forEach((donationDoc) => {
          const { amount } = donationDoc.data();
          sum += +amount;
        });

        setTotalDonation(sum);
      } catch (error) {
        console.error("Error fetching total donation:", error);
      }
    };

    fetchTotalDonation();
  }, []);
  return (
    <>
      <main id="main" class="main">
        <h5>{eventname}</h5>
        <Table responsive striped bordered hover size="sm">
          <thead>
            <tr
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                backgroundColor: "#1f88be",
                color: "white",
              }}
            >
              <th style={{ textAlign: "center" }}>#</th>

              <th style={{ textAlign: "left" }}>Name</th>
              <th style={{ textAlign: "left" }}>Date</th>

              <th style={{ textAlign: "right", paddingRight: "10px" }}>
                Amount
              </th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "12px" }}>
            {photos &&
              photos.map((item, index) => (
                <tr>
                  <td style={{ textAlign: "center", width: "5vh" }}>
                    {index + 1}
                  </td>

                  <td
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {item.name}
                  </td>
                  <td
                    style={{
                      textAlign: "left",
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    {item.date && formatTimestamp(item.date)}
                  </td>

                  <td
                    style={{
                      textAlign: "right",
                      paddingRight: "10px",
                      fontWeight: "bold",
                      color: "green",
                      width: "16vh",
                    }}
                  >
                    ₹ {item.amount}.00
                    <span
                      style={{
                        color: "grey",
                      }}
                    ></span>
                  </td>
                </tr>
              ))}
            <tr>
              <td></td>
              <td></td>
              <td style={{ textAlign: "right" }}>
                <b style={{ color: "red" }}>Total:</b>
              </td>
              <td
                style={{
                  backgroundColor: "#3a7824",
                  color: "white",
                  fontSize: "15px",
                  textAlign: "right",
                }}
              >
                ₹ <b>{totalDonation}</b>.00
              </td>
            </tr>
          </tbody>
        </Table>
      </main>
    </>
  );
}

export default EventFunds;
