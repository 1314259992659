import React, { useState, useEffect } from "react";
import { Button } from "semantic-ui-react";
import { useNavigate, Link } from "react-router-dom";
import { db } from "../../firebase-config";
import { onSnapshot } from "firebase/firestore";

import { Table } from "react-bootstrap";
import GoToTop from "../../GoToTop";

function MembersList() {
  const [photos, setPhotos] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("TedXtasks").orderBy("regno"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setPhotos(list);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  return (
    <>
      <GoToTop />

      <main id="main" class="main">
        <div className="section-title">
          <h2
            style={{
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              color: "#212A3E",
            }}
          >
            <span style={{ color: "red", fontWeight: "bolder" }}>TEDx</span>{" "}
            Management
          </h2>
        </div>
        <br />
        {/* Table */}

        <div className="row">
          <div className="col-3">
            <div className="card">
              <Link to="/tedxTaskManage">
                <div
                  className="card-body"
                  style={{ padding: "10px", cursor: "pointer" }}
                >
                  <h3>
                    <i class="fa fa-thumb-tack" aria-hidden="true"></i> Task
                    management
                  </h3>
                </div>
              </Link>
            </div>
          </div>
        </div>

        <Table responsive striped bordered hover size="sm">
          <thead>
            <tr
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                backgroundColor: "#1f88be",
                color: "white",
              }}
            >
              <th style={{ textAlign: "center", width: "4vh" }}>#</th>
              <th style={{ textAlign: "center", width: "12vh" }}>Profile</th>

              <th style={{ width: "20vh", textAlign: "left" }}>Details</th>
              <th style={{ width: "30vh", textAlign: "left" }}>Department</th>

              <th style={{ minWidth: "40vh", textAlign: "left" }}>
                Last Task Update
              </th>
              <th style={{ textAlign: "center" }}>Status</th>
              <th style={{ textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "12px" }}>
            {photos &&
              photos.map((item, index) => (
                <tr>
                  <td style={{ textAlign: "center", width: "5vh" }}>
                    {index + 1}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <img
                      src={item.photo}
                      alt=""
                      style={{
                        width: "10vh",
                        height: "12vh",
                        objectFit: "cover",
                      }}
                    />
                  </td>

                  <td
                    style={{
                      textAlign: "left",
                      minWidth: "25vh",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {item.regno}
                    <br></br>
                    {item.name}
                    <br></br>
                    <a style={{ color: "green" }}>
                      <i class="fa fa-phone-square"></i> {item.contact}
                    </a>
                  </td>

                  <td style={{ textAlign: "left" }}>{item.department}</td>
                  <td style={{ textAlign: "left" }}>
                    <i style={{ color: "grey" }}>Task: </i>
                    {item.task}
                    <br></br>
                    <i style={{ color: "grey" }}>Status: </i>
                    <b
                      style={{
                        color:
                          item.tstatus === "Assigned"
                            ? "orange"
                            : item.tstatus === "Not Submitted"
                            ? "red"
                            : item.tstatus === "Completed"
                            ? "green"
                            : "yellow",
                      }}
                    >
                      {item.tstatus}
                    </b>

                    <br></br>
                    <i style={{ color: "grey" }}>Submission Date: </i>
                    <b style={{ color: "red" }}>{item.lastdate}</b>
                  </td>

                  <td style={{ textAlign: "center", width: "30px" }}>
                    <div>
                      <span class="ui green center label">{item.status}</span>
                    </div>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      width: "10vh",
                    }}
                  >
                    <Button
                      style={{ marginTop: "2px" }}
                      size="small"
                      onClick={() => navigate(`/tedxtaskDetails/${item.id}`)}
                    >
                      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        {/* Table */}

        <br></br>
      </main>
    </>
  );
}

export default MembersList;
