import { useEffect, useState } from "react";
import { db } from "../../firebase-config";

function TMembersCount({ collectionName }) {
  const [docCount, setDocCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const collectionRef = db.collection("members");

    collectionRef
      .get()
      .then((querySnapshot) => {
        setDocCount(querySnapshot.size);
      })
      .catch((error) => {
        setError("Error retrieving data: " + error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [collectionName]);

  if (isLoading) {
    return <div class="ui active centered inline loader"></div>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <>
      <div>{docCount}</div>
    </>
  );
}
export default TMembersCount;
