import React, { useState, useEffect } from "react";
import { Button, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import { db } from "../../firebase-config";
import { useParams, useNavigate } from "react-router-dom";
import GoToTop from "../../GoToTop";
import ModalAdd from "./ModalMAdd";
import ModalEdit from "./ModalMEdit ";
import ModalTrash from "./ModalMDelete";
import {
  addDoc,
  updateDoc,
  doc,
  collection,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { Table } from "react-bootstrap";

const initialState = {
  name: "",
  regno: "",
  dept: "",
};

const EditEvents = () => {
  const [data, setData] = useState(initialState);
  const { name, regno, dept, contact } = data;

  const [errors, setErrors] = useState({});

  const [isSubmitted, setIsSubmitted] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    id && getSinglePhoto();
  }, [id]);

  const getSinglePhoto = async () => {
    const docRef = doc(db, "RegisteredEvents", id);
    const snapshot = await getDoc(docRef, "RegisteredEvents");
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };
  const [events, setEvents] = useState([]);
  useEffect(() => {
    const unsub = onSnapshot(
      db
        .collection("RegisteredEvents")
        .doc(id)
        .collection("Events")
        .orderBy("Edate"),

      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setEvents(list);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};
    if (!name) {
      errors.name = "Name is required";
    }

    if (!regno) {
      errors.regno = "Regno. is required";
    }

    if (!dept) {
      errors.dept = "Department is required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (Object.keys(errors).length) return setErrors(errors);

    setIsSubmitted("Details Update!");
    setTimeout(() => {
      setIsSubmitted("");
    }, 2000);

    if (!id) {
      try {
        await addDoc(collection(db, "RegisteredEvents"), {
          ...data,
          uid: id,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await updateDoc(doc(db, "RegisteredEvents", id), {
          ...data,
          uid: id,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formatTimestamp1 = (eventdate) => {
    const date = new Date(eventdate);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const [openA, setOpenA] = useState(false);
  const [modaladd, setModalAdd] = useState({});
  const handleAdd = () => {
    setOpenA(true);
    setModalAdd();
  };

  const [openE, setOpenE] = useState(false);
  const [modaledit, setModalEdit] = useState({});
  const handleEdit = (item) => {
    setOpenE(true);
    setModalEdit(item);
  };

  const [openT, setOpenT] = useState(false);
  const [modaltrash, setModalTrash] = useState({});
  const handleTrash = (item) => {
    setOpenT(true);
    setModalTrash(item);
  };

  return (
    <>
      <GoToTop />
      <main id="main" class="main">
        <div class="main-body">
          <div class="row gutters-sm">
            <div class="col-md-3 mb-3">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column align-items-center text-center my-2">
                    <img
                      src="https://cdn-icons-png.flaticon.com/512/1077/1077063.png "
                      alt="Admin"
                      style={{
                        width: "120px",
                        height: "130px",
                        objectFit: "cover",
                        borderRadius: "5px",
                      }}
                    />

                    <div>
                      <h3
                        style={{
                          marginTop: "38px",
                        }}
                      >
                        {name}
                      </h3>
                      <p style={{ fontSize: "10px", marginTop: "-14px" }}>
                        <b>ID:</b> {id}
                      </p>
                      <h6>
                        ({" "}
                        <span style={{ color: "red", fontWeight: "bold" }}>
                          {regno}
                        </span>{" "}
                        )
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-9">
              {" "}
              <div class="card mb-3">
                <div class="card-body">
                  <h4
                    style={{
                      backgroundColor: "#212A3E",
                      color: "white",
                      maxWidth: "25vh",
                      padding: "7px",
                      marginTop: "10px",
                    }}
                  >
                    Member Details:
                  </h4>

                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-3 my-2">
                        <Form.Input
                          error={errors.name ? { content: errors.name } : null}
                          label="Name"
                          name="name"
                          placeholder="Name"
                          onChange={handleChange}
                          value={name}
                        />
                      </div>
                      <div className="col-lg-3 my-2">
                        <Form.Input
                          error={
                            errors.regno ? { content: errors.regno } : null
                          }
                          label="Reg no."
                          name="regno"
                          placeholder="Registration number"
                          onChange={handleChange}
                          value={regno}
                        />
                      </div>

                      <div className="col-lg-3 my-2">
                        <Form.Input
                          error={
                            errors.contact ? { content: errors.contact } : null
                          }
                          label="Contact No."
                          name="contact"
                          placeholder="Contact No."
                          onChange={handleChange}
                          value={contact}
                        />
                      </div>

                      <div className="col-lg-3 my-2">
                        <Form.Field
                          error={errors.dept ? { content: errors.dept } : null}
                          label="Department"
                          style={{ height: "4.8vh" }}
                          name="dept"
                          control="select"
                          value={dept}
                          onChange={handleChange}
                        >
                          <option value="None">--Select--</option>
                          <option value="Member">Member</option>
                          <option value="Coordinator">Coordinator</option>
                          <option value="Board Member">Board Member</option>
                        </Form.Field>
                      </div>
                    </div>

                    <h5 style={{ color: "green" }}>{isSubmitted}</h5>

                    <Button type="submit" color="linkedin">
                      Update
                    </Button>
                  </Form>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <h4
                    style={{
                      backgroundColor: "#212A3E",
                      color: "white",
                      maxWidth: "25vh",
                      padding: "7px",
                      marginTop: "10px",
                    }}
                  >
                    Event Details:
                  </h4>

                  <Button
                    size="small"
                    color="orange"
                    onClick={() => handleAdd()}
                  >
                    <i class="fa fa-plus"></i> Add Event
                  </Button>
                  {openA && (
                    <ModalAdd
                      id={id}
                      data={data}
                      open={openA}
                      setOpen={setOpenA}
                      // handleDelete={handleDelete}
                      {...modaladd}
                    />
                  )}

                  <div className="row my-2">
                    <Table responsive striped bordered hover size="sm">
                      <thead>
                        <tr
                          style={{
                            fontSize: "12px",
                            fontWeight: "normal",
                          }}
                        >
                          <th style={{ textAlign: "center" }}>#</th>

                          <th style={{ textAlign: "left" }}>Event Name</th>
                          <th style={{ textAlign: "left" }}>Event Date</th>
                          <th style={{ textAlign: "left" }}>Event Venue</th>
                          <th style={{ textAlign: "center" }}>Status</th>

                          <th style={{ textAlign: "center" }}>Certificate</th>
                          <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        {events.map((item, index) => (
                          <tr key={item.id}>
                            <th style={{ textAlign: "center" }}>{index + 1}</th>

                            <td style={{ textAlign: "left" }}>
                              <b>{item.Ename}</b>
                            </td>

                            <td style={{ textAlign: "left" }}>
                              {item.eventdate &&
                                formatTimestamp1(item.eventdate)}
                            </td>

                            <td style={{ textAlign: "left" }}>{item.Evenue}</td>

                            <td
                              style={{
                                textAlign: "center",
                                fontWeight: "500",
                                color:
                                  item.status === "Present"
                                    ? "green"
                                    : item.status === "Absent"
                                    ? "red"
                                    : "orange",
                              }}
                            >
                              {item.status}
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                              }}
                            >
                              {item.certificate.length > 0 ? (
                                <a href={item.certificate} target="_blank">
                                  <i class="fa fa-download"></i>
                                </a>
                              ) : (
                                <i
                                  class="fa fa-times"
                                  style={{ color: "red" }}
                                ></i>
                              )}
                            </td>
                            <td
                              style={{ textAlign: "center", minWidth: "10vh" }}
                            >
                              <i
                                class="fa fa-pencil"
                                onClick={() => handleEdit(item)}
                                style={{
                                  marginRight: "10px",
                                  cursor: "pointer",
                                }}
                              ></i>
                              {openE && (
                                <ModalEdit
                                  open={openE}
                                  setOpen={setOpenE}
                                  {...modaledit}
                                />
                              )}

                              <i
                                class="fa fa-trash"
                                onClick={() => handleTrash(item)}
                                style={{
                                  marginLeft: "10px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                              ></i>
                              {openT && (
                                <ModalTrash
                                  open={openT}
                                  setOpen={setOpenT}
                                  // handleDelete={handleDelete}
                                  {...modaltrash}
                                />
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <div className="row"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
      </main>
    </>
  );
};

export default EditEvents;
