import React, { useState, useEffect } from "react";
import { Button, Icon, Form } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { db } from "../../firebase-config";
import { onSnapshot, serverTimestamp } from "firebase/firestore";
import ModalEdit from "./ModalEdit";
import { Table } from "react-bootstrap";
import GoToTop from "../../GoToTop";

function EventsList() {
  const [Ename, setName] = useState(null);
  const [eventdate, setDate] = useState(null);
  const [Evenue, setVenue] = useState(null);
  const [registration, setReg] = useState(null);
  const [loader, setLoader] = useState("");
  const [success, setsuccessLoader] = useState("");

  const [availP, setAvail] = useState([]);
  // getting Available projects from firestore collection and updating the state
  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("Events").orderBy("postedOn"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setAvail(list);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  ////

  const [openE, setOpenE] = useState(false);
  const [modaledit, setModalEdit] = useState({});
  const handleEdit = (item) => {
    setOpenE(true);
    setModalEdit(item);
  };

  const current = new Date();
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const postedOn = current.toLocaleDateString("en-US", options);

  const handleSubmit = async () => {
    try {
      setLoader("Adding Event ! Please wait...");
      setsuccessLoader("");
      db.collection("Events").doc(Ename).set({
        Ename,
        eventdate,
        Evenue,
        certificate: "Not Available",
        registration,
        postedOn,
        posted: serverTimestamp(),
      });

      // Reset form fields and close the modal
      // Reset form fields and close the modal
      setLoader("");
      setsuccessLoader("Event added successfully!");
      setName("");

      setDate("");
      setVenue("");
      setReg("");

      setTimeout(() => {
        setLoader("");
        setsuccessLoader("");
      }, 2000);
    } catch (error) {
      console.log("Error submitting applied project:", error);
    }
  };

  const formatTimestamp1 = (eventdate) => {
    const date = new Date(eventdate);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  return (
    <>
      <GoToTop />
      <main id="main" class="main">
        <div className="section-title">
          <h2
            style={{
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              color: "#212A3E",
            }}
          >
            Add Offline Event
          </h2>
        </div>

        {/* Table */}
        <div class="card" style={{ padding: "10px", marginTop: "8px" }}>
          <Form>
            {loader && (
              <Alert
                variant="warning"
                style={{ fontSize: "11px", textAlign: "center" }}
              >
                {loader}
              </Alert>
            )}
            {success && (
              <Alert
                variant="success"
                style={{ fontSize: "11px", textAlign: "center" }}
              >
                {success}
              </Alert>
            )}

            <div className="row">
              <div className="col-lg-3 my-2">
                <Form.Input
                  placeholder="Event Name"
                  label="Event Name"
                  required
                  onChange={(e) => setName(e.target.value)}
                  value={Ename}
                />
              </div>
              <div className="col-lg-3 my-2">
                <Form.Input
                  required
                  type="datetime-local"
                  value={eventdate}
                  onChange={(e) => setDate(e.target.value)}
                  placeholder="Event Date"
                  label="Event Date"
                />
              </div>

              <div className="col-lg-3 my-2">
                <Form.Input
                  required
                  type="text"
                  value={Evenue}
                  onChange={(e) => setVenue(e.target.value)}
                  placeholder="Event Venue"
                  label="Event Venue"
                />
              </div>

              <div className="col-lg-3 my-2">
                <Form.Field
                  required
                  control="select"
                  value={registration}
                  onChange={(e) => setReg(e.target.value)}
                  placeholder="Event Venue"
                  label="Event Venue"
                >
                  <option value="">--Select--</option>
                  <option value="Open">Registration Open</option>
                  <option value="Closed">Registration Closed</option>
                </Form.Field>
              </div>
            </div>
            <div class="text-right" style={{ float: "right" }}>
              {Ename !== null && eventdate !== null && Evenue !== null ? (
                <Button
                  color="blue"
                  size="small"
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary"
                >
                  Apply
                </Button>
              ) : (
                <Button
                  disabled
                  color="blue"
                  size="small"
                  type="submit"
                  className="btn btn-primary"
                >
                  Add Event
                </Button>
              )}
            </div>
          </Form>
        </div>
        <br></br>

        <div className="section-title">
          <h2
            style={{
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              color: "#212A3E",
            }}
          >
            Offline Events
          </h2>
        </div>
        <Table
          responsive
          striped
          bordered
          hover
          size="sm"
          style={{ fontSize: "13px", marginTop: "8px" }}
        >
          <thead>
            <tr style={{ backgroundColor: "#1f88be", color: "white" }}>
              <th scope="col">#</th>
              <th scope="col">Event Name</th>
              <th scope="col">Event Date</th>
              <th scope="col">Event Venue</th>

              <th scope="col" style={{ textAlign: "center" }}>
                Certificate
              </th>
              <th scope="col" style={{ textAlign: "center" }}>
                Registration
              </th>
              <th scope="col" style={{ textAlign: "center" }}>
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {availP.length > 0 ? (
              <>
                {availP.map((item, index) => (
                  <tr key={item.id}>
                    <th scope="row" style={{ width: "5vh" }}>
                      {index + 1}
                    </th>

                    <td
                      style={{
                        minWidth: "26vh",
                        textTransform: "uppercase",
                      }}
                    >
                      {item.Ename}
                    </td>
                    <td
                      style={{
                        minWidth: "20vh",
                      }}
                    >
                      {item.eventdate && formatTimestamp1(item.eventdate)}
                    </td>
                    <td
                      style={{
                        minWidth: "20vh",
                      }}
                    >
                      {item.Evenue}
                    </td>

                    <td
                      style={{
                        minWidth: "11vh",
                        textAlign: "center",
                      }}
                    >
                      {item.certificate}
                    </td>

                    <td
                      style={{
                        minWidth: "8vh",
                        textAlign: "center",
                      }}
                    >
                      {item.registration && item.registration === "Open" ? (
                        <b style={{ color: "green" }}>
                          <i class="fa fa-external-link" aria-hidden="true"></i>
                        </b>
                      ) : (
                        <>
                          <b style={{ color: "red" }}>
                            <i class="fa fa-times"></i>
                          </b>
                        </>
                      )}
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <Button
                        size="small"
                        color="orange"
                        onClick={() => handleEdit(item)}
                      >
                        <i class="fa fa-pencil"></i>
                      </Button>
                      {openE && (
                        <ModalEdit
                          open={openE}
                          setOpen={setOpenE}
                          // handleDelete={handleDelete}
                          {...modaledit}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="9" style={{ textAlign: "center", color: "red" }}>
                  No data found
                </td>
              </tr>
            )}
            <tr></tr>
          </tbody>
        </Table>
        {/* Table */}
      </main>
    </>
  );
}

export default EventsList;
