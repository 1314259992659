import React, { useState } from "react";
import { db } from "../../firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import "react-quill/dist/quill.snow.css";
import { serverTimestamp } from "firebase/firestore";

const ModalEdit = ({ open, setOpen, task, lastdate, id, userId, tstatus }) => {
  const [updateTask, setTask] = useState(task);
  const [updateDate, setDate] = useState(lastdate);
  const [updatestatus, setStatus] = useState(tstatus);

  const handleSubmit = async () => {
    try {
      // Create a new document in Firestore collection
      await db
        .collection("TedXtasks")
        .doc(userId)
        .collection("Tasks")
        .doc(id)
        .update({
          task: updateTask,
          lastdate: updateDate,
          tstatus: updatestatus,
          updatedOn: serverTimestamp(),
        });

      // Reset form fields and close the modal

      setOpen(false);
    } catch (error) {
      console.log("Error updating applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{ width: "100%", height: "100%", backgroundColor: "transparent" }}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Update Task Details
            </h5>
            <button
              type="button"
              onClick={() => setOpen(false)}
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <Form>
                <Form.TextArea
                  placeholder="Update Task"
                  label="Task"
                  required
                  onChange={(e) => setTask(e.target.value)}
                  defaultValue={task}
                ></Form.TextArea>

                <br></br>
                <Form.Input
                  placeholder="Update Last Date"
                  label="Submission Date"
                  required
                  type="date"
                  onChange={(e) => setDate(e.target.value)}
                  defaultValue={lastdate}
                />

                <br></br>
                <Form.Field
                  required
                  control="select"
                  defaultValue={tstatus}
                  onChange={(e) => setStatus(e.target.value)}
                  placeholder="Status"
                  label="Status"
                >
                  <option value="Assigned">Assigned</option>
                  <option value="Unassigned">Unassigned</option>
                  <option value="Completed">Completed</option>
                </Form.Field>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              color="blue"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEdit;
