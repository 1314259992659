import React, {useState} from "react";
import {db} from "../../firebase-config";
import {Button, Modal, Form} from "semantic-ui-react";
import "react-quill/dist/quill.snow.css";
import {useParams} from "react-router-dom";

const ModalMDelete = ({
  open,
  setOpen,
  Ename,
  eventdate,
  Evenue,
  certificate,
  status,
  Edate,
}) => {
  const {id} = useParams();

  const handleSubmit = async () => {
    try {
      // Create a new document in Firestore collection
      await db
        .collection("RegisteredEvents")
        .doc(id)
        .collection("Events")
        .doc(Ename)
        .delete({
          certificate,
          status,
          eventdate,
          Evenue,
          Ename,
          Edate,
        });
      setOpen(false);
    } catch (error) {
      console.log("Error updating applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{width: "100%", height: "100%", backgroundColor: "transparent"}}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{backgroundColor: "red", color: "white"}}
          >
            <h5 class="modal-title" id="exampleModalLabel">
              Delete {Ename}
            </h5>
            <button
              type="button"
              onClick={() => setOpen(false)}
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h5>Are you sure you want to delete?</h5>
          </div>
          <div class="modal-footer">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              color="red"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalMDelete;
