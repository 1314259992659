import React, { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { Button, Modal, Form } from "semantic-ui-react";
import { Alert } from "react-bootstrap";
import GoToTop from "../../GoToTop";
import { serverTimestamp, onSnapshot } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";

const TedTaskDetails = () => {
  const [names, setNames] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedDetails, setSelectedDetails] = useState({});

  const [success, setsuccessLoader] = useState("");

  const collectionRef = db.collection("TedXtasks");

  // Realtime data hook
  const [data] = useCollectionData(collectionRef);

  // Populate names when data changes
  useEffect(() => {
    if (data) {
      const nameList = data.map((item) => item.name);
      setNames(nameList);
    }
  }, [data]);

  // Fetch details for the selected name
  useEffect(() => {
    const fetchDetails = async () => {
      if (selectedName) {
        const querySnapshot = await collectionRef
          .where("name", "==", selectedName)
          .get();
        if (!querySnapshot.empty) {
          const details = querySnapshot.docs[0].data();
          setSelectedDetails(details);
        }
      }
    };

    fetchDetails();
  }, [selectedName, collectionRef]);

  // Handle select change
  const handleSelectChange = (event) => {
    setSelectedName(event.target.value);
  };

  const today = new Date();
  const chattime =
    today.getHours().toString().padStart(2, "0") +
    ":" +
    today.getMinutes().toString().padStart(2, "0") +
    ":" +
    today.getSeconds().toString().padStart(2, "0");
  const current = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };

  const chatdate = current.toLocaleString("en-US", options);

  const [task, setTask] = useState("");
  const [lastdate, setLastDate] = useState(null);
  ///Submit
  const handleSubmit = async () => {
    setsuccessLoader("");

    try {
      // Create a storage reference

      db.collection("TedXtasks")
        .doc(selectedDetails.id)
        .collection("Tasks")
        .doc(`${chatdate}-${chattime}`)
        .set({
          postedOn: serverTimestamp(),
          id: `${chatdate}-${chattime}`,
          lastdate,
          task,
          tstatus: "Assigned",
          name: selectedDetails.name,
          dept: selectedDetails.dept,
          userID: selectedDetails.id,
        })
        .then(() => {
          db.collection("TedXtasks").doc(selectedDetails.id).update({
            task,
            lastdate,
            postedOn: serverTimestamp(),
            tstatus: "Assigned",
          });
        });

      // Reset form fields and close the modal

      setsuccessLoader("Task Assigned Successfully!");

      setTask("");
      setLastDate("");
      setNames(null);

      setTimeout(() => {
        setsuccessLoader("");
      }, 2000);
    } catch (error) {
      console.log("Error submitting task project:", error);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////

  const [availP, setAvail] = useState([]);
  // getting Available projects from firestore collection and updating the state
  useEffect(() => {
    const unsub = onSnapshot(
      db.collectionGroup("Tasks"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setAvail(list);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const formatTimestamp = (updatedOn) => {
    const date = updatedOn.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp1 = (postedOn) => {
    const date = postedOn.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };
  return (
    <>
      <GoToTop />
      <main id="main" class="main">
        <div className="section-title">
          <h2
            style={{
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              color: "#212A3E",
            }}
          >
            <span style={{ color: "red", fontWeight: "bolder" }}>TEDx</span>{" "}
            Task Assigment
          </h2>
        </div>
        <br />

        <div className="card">
          <div className="card-body my-3">
            <div className="row">
              <div className="col-lg-8 my-3">
                <Form>
                  <Form.TextArea
                    label="Task"
                    required={true}
                    placeholder="Write briefly about the task..."
                    onChange={(e) => setTask(e.target.value)}
                    value={task}
                  ></Form.TextArea>

                  <Form.Input
                    required={true}
                    type="date"
                    value={lastdate}
                    onChange={(e) => setLastDate(e.target.value)}
                    placeholder="Date of Submission"
                    label="Date of Submission"
                  />
                </Form>
              </div>
              <div
                className="col-lg-4 my-3"
                style={{ borderLeft: "1px solid #c4c4c4" }}
              >
                <Form>
                  {!task || !lastdate ? (
                    <>
                      <Form.Field
                        control="select"
                        disabled
                        label="Select Member"
                      >
                        <option value="" disabled>
                          Select a Member
                        </option>
                      </Form.Field>
                    </>
                  ) : (
                    <>
                      <Form.Field
                        control="select"
                        label="Select Member"
                        value={selectedName}
                        onChange={handleSelectChange}
                      >
                        <option value="" disabled>
                          Select a Member
                        </option>
                        {names.map((name, index) => (
                          <option key={index} value={name}>
                            {name}
                          </option>
                        ))}
                      </Form.Field>
                    </>
                  )}
                </Form>
                <div
                  className="card"
                  style={{ border: "1px solid #e6e6e6", marginTop: "3px" }}
                >
                  <div
                    className="card-body"
                    style={{ padding: "9px", fontSize: "12px" }}
                  >
                    <div className="row">
                      <div className="col-3">
                        <img
                          src={selectedDetails.photo}
                          alt="Profile"
                          className="img-fluid"
                        />
                      </div>
                      <div
                        className="col-9"
                        style={{ borderLeft: "1px solid #c7c7c7" }}
                      >
                        <b>
                          <i style={{ color: "grey", fontWeight: "normal" }}>
                            Name:
                          </i>{" "}
                          {selectedDetails.name}
                          <br />
                          <i style={{ color: "grey", fontWeight: "normal" }}>
                            ID:
                          </i>{" "}
                          <span style={{ color: "grey", fontWeight: "normal" }}>
                            {selectedDetails.id}
                          </span>
                          <br />
                          <i style={{ color: "grey", fontWeight: "normal" }}>
                            Department:
                          </i>{" "}
                          {selectedDetails.dept}
                          <br />
                          <i style={{ color: "grey", fontWeight: "normal" }}>
                            Contact:
                          </i>{" "}
                          {selectedDetails.contact}
                        </b>
                      </div>
                    </div>
                  </div>
                </div>

                {!selectedName || !task || !lastdate ? (
                  <>
                    <Button color="linkedin" size="small" disabled>
                      Confirm Task
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      color="linkedin"
                      size="small"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Confirm Task
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body my-3">
            <div className="row">
              {availP.length > 0 ? (
                <>
                  {availP.map((item, index) => (
                    <div
                      key={item.id}
                      className="card"
                      style={{
                        padding: "10px",
                        fontSize: "12px",
                        marginBottom: "7px",
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-7">
                          <p>
                            <span
                              style={{
                                color: "grey",
                                fontStyle: "italic",
                                fontWeight: "normal",
                              }}
                            >
                              Task {index + 1}:{" "}
                            </span>
                            <b>{item.task}</b>
                          </p>

                          <p>
                            <span
                              style={{
                                color: "grey",
                                fontStyle: "italic",
                                fontWeight: "normal",
                              }}
                            >
                              Remark :{" "}
                            </span>
                            {item.atten}
                          </p>
                        </div>
                        <div
                          className="col-lg-4"
                          style={{
                            borderLeft: "1px solid grey",
                          }}
                        >
                          <i style={{ color: "grey" }}>Submission Date: </i>{" "}
                          <b style={{ color: "red" }}>{item.lastdate}</b>
                          <br />
                          <i style={{ color: "grey" }}>Assigned On: </i>{" "}
                          <b style={{ color: "grey" }}>
                            {" "}
                            {item.postedOn && formatTimestamp1(item.postedOn)}
                          </b>
                          <br />
                          <i style={{ color: "grey" }}>Status: </i>{" "}
                          <b
                            style={{
                              color:
                                item.tstatus === "Assigned"
                                  ? "orange"
                                  : item.tstatus === "Unassigned"
                                  ? "red"
                                  : item.tstatus === "Completed"
                                  ? "green"
                                  : "yellow",
                            }}
                          >
                            {item.tstatus}
                          </b>
                          {item.updatedOn !== null &&
                          item.updatedOn !== undefined ? (
                            <>
                              {" "}
                              <br />
                              <i style={{ color: "grey" }}>Updated On: </i>{" "}
                              <b style={{ color: "grey" }}>
                                {item.updatedOn &&
                                  formatTimestamp(item.updatedOn)}
                              </b>
                            </>
                          ) : null}
                        </div>
                        <div
                          className="col-lg-1"
                          style={{
                            borderLeft: "1px solid grey",
                            textAlign: "center",
                            fontSize: "20px",
                          }}
                        >
                          <i
                            // onClick={() => handleEdit(item)}
                            style={{
                              cursor: "pointer",
                              color: "orange",
                              marginTop: "20px",
                            }}
                            class="fa fa-pencil"
                          ></i>
                          {/* {openE && (
                            <ModalEdit
                              open={openE}
                              setOpen={setOpenE}
                              userId={id}
                           
                              {...modaledit}
                            />
                          )} */}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div colSpan="9" style={{ textAlign: "center", color: "red" }}>
                  No task found
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default TedTaskDetails;
