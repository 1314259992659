import React, { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { Button } from "semantic-ui-react";
import { useNavigate, Link } from "react-router-dom";
import GoToTop from "../../GoToTop";
import Swal from "sweetalert2";
import { onSnapshot, doc, deleteDoc } from "firebase/firestore";

import { Table } from "react-bootstrap";
function PendingPay() {
  const [photos, setPhotos] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("AAPendingPays").orderBy("date", "desc"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setPhotos(list);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  const formatTimestamp = (date) => {
    const datee = date.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return datee.toLocaleString("en-US", options);
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "AAPendingPays", id));
      setPhotos((prevPhotos) => prevPhotos.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const confirmDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(id);
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <GoToTop />
      <main>
        <h5>PendingPay</h5>

        <div className="row">
          <Table responsive striped bordered hover size="sm">
            <thead>
              <tr
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  backgroundColor: "#1f88be",
                  color: "white",
                }}
              >
                <th style={{ textAlign: "center" }}>#</th>

                <th style={{ textAlign: "left" }}>Name</th>
                <th style={{ textAlign: "left" }}>Contact</th>

                <th style={{ textAlign: "left" }}>Email</th>
                <th style={{ textAlign: "left" }}>Hometown</th>
                <th style={{ textAlign: "left" }}>Amount</th>
                <th style={{ textAlign: "left" }}>Date</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "12px" }}>
              {photos &&
                photos.map((item, index) => (
                  <tr>
                    <td style={{ textAlign: "center", width: "5vh" }}>
                      {index + 1}
                    </td>

                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {item.name}
                    </td>

                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {item.phone}
                    </td>

                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {item.email}
                    </td>

                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {item.hometown}
                    </td>

                    <td
                      style={{
                        textAlign: "left",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      ₹ {item.amount}.00
                    </td>

                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {item.date && formatTimestamp(item.date)}
                    </td>

                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <i
                        style={{
                          cursor: "pointer",
                          color: "red",
                          fontSize: "15px",
                        }}
                        onClick={() => confirmDelete(item.id)}
                        class="fa fa-trash"
                      ></i>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          {/* <div className="col-lg-4">
          {" "}
          <Form onSubmit={handleSubmit}>
            <Form.Input
              label="Purpose"
              type="text"
              value={purpose}
              onChange={(e) => setPurspose(e.target.value)}
            />

            <Form.Input
              label="Date"
              type="date"
              value={ddate}
              onChange={(e) => setDate(e.target.value)}
            />

            <Form.Field
              label="Type"
              control="select"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="Deposit">Deposit</option>
              <option value="Withdrawal">Withdrawal</option>
            </Form.Field>

            <Form.Input
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />

            <Button type="submit">Add Transaction</Button>
          </Form>
        </div> */}
        </div>
      </main>
    </>
  );
}

export default PendingPay;
