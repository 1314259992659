import React, { useState, useEffect } from "react";
import { Button, Icon, Form } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import GoToTop from "../GoToTop";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../firebase-config";
import { Table } from "react-bootstrap";
import { onSnapshot } from "firebase/firestore";
function Attendance() {
  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();
  const currentMonthYear = `${month}${year}`;
  const [students, loading, error] = useCollectionData(
    db.collection("attendance").orderBy("regno")
  );

  const [updatedStudents, setUpdatedStudents] = useState([]);

  const handleChange = (event, student) => {
    const { value } = event.target;

    setUpdatedStudents((prevStudents) => [
      ...prevStudents.filter((s) => s.id !== student.id),
      { ...student, [currentMonthYear + "A"]: value },
    ]);
  };

  const handleSave = () => {
    updatedStudents.forEach((student) => {
      db.collection("attendance")
        .doc(student.id)
        .update({
          [currentMonthYear + "A"]: student[currentMonthYear + "A"],
        });
    });
    alert("Attendance posted successfully");
  };

  const [photos, setPhotos] = useState([]);

  const [loadingg, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("attendance").orderBy("regno"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setPhotos(list);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );
    return () => {
      unsub();
    };
  }, []);

  const currentMonth = `${month} ${year}`;

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <>
      {" "}
      <GoToTop />
      <div className="container-fluid" style={{ marginTop: "12vh" }}>
        <h2
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat, sans-serif",
            color: "#212A3E",
          }}
        >
          ATTENDANCE %
        </h2>

        {/* Table */}

        <Table responsive striped bordered hover size="sm">
          <thead>
            <tr style={{ fontSize: "12px", fontWeight: "normal" }}>
              <th style={{ textAlign: "center" }}>#</th>

              <th style={{ textAlign: "left" }}>Reg No.</th>
              <th style={{ textAlign: "left" }}>Name</th>

              <th style={{ textAlign: "center" }}>{currentMonth} A</th>
              <th style={{ textAlign: "center" }}>{currentMonth} B</th>
              <th style={{ textAlign: "center" }}>Action</th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "12px" }}>
            {students.map((student, index) => (
              <tr key={student.id} style={{ display: `${student.status}` }}>
                <td style={{ textAlign: "center", width: "8vh" }}>
                  {index + 1}
                </td>
                <td style={{ width: "20vh", textAlign: "left" }}>
                  {student.regno}
                </td>
                <td style={{ textAlign: "left" }}>{student.name}</td>

                <td style={{ width: "19vh", minWidth: "15vh" }}>
                  <Form>
                    <Form.Field
                      style={{
                        maxWidth: "12vh",
                      }}
                      control="select"
                      disabled={student.disabled}
                      value={
                        updatedStudents.find((s) => s.id === student.id)?.[
                          currentMonthYear + "A"
                        ] ?? student[currentMonthYear + "A"]
                      }
                      onChange={(event) => handleChange(event, student)}
                    >
                      <option value="">--Select--</option>
                      <option value="Absent">Absent</option>
                      <option value="Present">Present</option>
                    </Form.Field>
                  </Form>
                </td>

                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "800",
                  }}
                >
                  {student[currentMonthYear + "A"]}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <br></br>
        <br></br>
        <div className="text-center">
          <Button
            icon
            labelPosition="left"
            size="small"
            color="orange"
            onClick={handleSave}
          >
            {" "}
            <Icon name="check" /> Post Attendance
          </Button>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    </>
  );
}
export default Attendance;
