import React, { useState, useEffect } from "react";
import { Button, Form } from "semantic-ui-react";

import { db, auth } from "../../firebase-config";
import { useParams } from "react-router-dom";
import GoToTop from "../../GoToTop";

import {
  addDoc,
  updateDoc,
  doc,
  collection,
  onSnapshot,
} from "firebase/firestore";
import { Table } from "react-bootstrap";

const initialState = {
  name: "",
  regno: "",
  dept: "",
};

const calculatePercentage = (status) => {
  return status === "Present" ? 100 : 0;
};

const EditAtten = () => {
  const [data, setData] = useState(initialState);
  const {
    name,
    regno,
    dept,
    contact,
    Jan23a,
    Jan23b,
    Feb23a,
    Feb23b,
    Mar23a,
    Mar23b,
    Apr23a,
    Apr23b,
    May23a,
    May23b,
    June2023A,
    June2023B,
    July2023A,
    July2023B,
    August2023A,
    August2023B,
    September2023A,
    September2023B,
    October2023A,
    October2023B,
    November2023A,
    November2023B,
    December2023A,
    December2023B,
    January2024A,
    January2024B,
    Jan23af,
    Jan23bf,
    Feb23af,
    Feb23bf,
    Mar23af,
    Mar23bf,
    Apr23af,
    Apr23bf,
    May23af,
    May23bf,
    June2023ADesc,
    June2023BDesc,
    July2023ADesc,
    July2023BDesc,
    August2023ADesc,
    August2023BDesc,
    September2023ADesc,
    September2023BDesc,
    October2023ADesc,
    October2023BDesc,
    November2023ADesc,
    November2023BDesc,
    December2023ADesc,
    December2023BDesc,
    January2024ADesc,
    January2024BDesc,
  } = data;

  const [errors, setErrors] = useState({});

  const [isSubmitted, setIsSubmitted] = useState("");

  const { id } = useParams();

  const [leaves, setLeaves] = useState([]);

  useEffect(() => {
    const fetchUserData = (uid) => {
      const query = db
        .collection("Leaves")
        .where("uid", "==", id)
        .where("status", "==", "Approved");

      const unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setLeaves(documents);
        },
        (error) => {
          console.error(error);
        }
      );

      return unsubscribe;
    };

    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        const unsubscribeSnapshot = fetchUserData(uid);

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        // No current user
        setLeaves([]);
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, []);

  useEffect(() => {
    if (id) {
      const docRef = doc(db, "attendance", id);
      const unsubscribe = onSnapshot(docRef, (snapshot) => {
        if (snapshot.exists()) {
          setData({ ...snapshot.data() });
        }
      });

      return () => {
        unsubscribe(); // Unsubscribe from the snapshot listener when the component unmounts
      };
    }
  }, [id]);

  const [updateStatus, setUpdatedStatus] = useState("");
  const [status, setStatus] = useState("");

  const handleSubmitU = async () => {
    try {
      // Create a new document in Firestore collection
      db.collection("attendance")
        .doc(id)
        .update({
          [updateStatus]: status,
        });
      setStatus("");
      setUpdatedStatus("");

      // Reset form fields and close the modal
    } catch (error) {
      console.log("Error updating applied project:", error);
    }
  };

  const [updateDesc, setUpdatedDesc] = useState("");
  const [desc, setDesc] = useState("");
  const handleSubmitD = async () => {
    try {
      // Create a new document in Firestore collection
      db.collection("attendance")
        .doc(id)
        .update({
          [updateDesc]: desc,
        });
      setDesc("");
      setUpdatedDesc("");

      // Reset form fields and close the modal
    } catch (error) {
      console.log("Error updating applied project:", error);
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};

    if (!dept) {
      errors.dept = "Department is required";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (Object.keys(errors).length) return setErrors(errors);

    setIsSubmitted("Details Update!");
    setTimeout(() => {
      setIsSubmitted("");
    }, 2000);

    if (!id) {
      try {
        await addDoc(collection(db, "attendance"), {
          ...data,
          id: id,
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await updateDoc(doc(db, "attendance", id), {
          ...data,
          id: id,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const formatTimestamp1 = (todate) => {
    const date = new Date(todate);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleString("en-US", options);
  };

  const formatTimestamp2 = (enddate) => {
    const date = new Date(enddate);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleString("en-US", options);
  };

  const A = calculatePercentage(Jan23a);
  const B = calculatePercentage(Jan23b);
  const C = calculatePercentage(Feb23a);
  const D = calculatePercentage(Feb23b);
  const E = calculatePercentage(Mar23a);
  const F = calculatePercentage(Mar23b);
  const G = calculatePercentage(Apr23a);
  const H = calculatePercentage(Apr23b);
  const I = calculatePercentage(May23a);
  const J = calculatePercentage(May23b);
  const K = calculatePercentage(June2023A);
  const L = calculatePercentage(June2023B);
  const M = calculatePercentage(July2023A);
  const N = calculatePercentage(July2023B);
  const O = calculatePercentage(August2023A);
  const P = calculatePercentage(August2023B);
  const Q = calculatePercentage(September2023A);
  const R = calculatePercentage(September2023B);
  const S = calculatePercentage(October2023A);
  const T = calculatePercentage(October2023B);
  const U = calculatePercentage(November2023A);
  const V = calculatePercentage(November2023B);
  const W = calculatePercentage(December2023A);
  const X = calculatePercentage(December2023B);

  const overallPercentage = (
    (A +
      B +
      C +
      D +
      E +
      F +
      G +
      H +
      I +
      J +
      K +
      L +
      M +
      N +
      O +
      P +
      Q +
      R +
      S +
      T +
      U +
      V +
      W +
      X) /
    23
  ).toFixed(2);

  let fontColor;
  if (overallPercentage > 80.0) {
    fontColor = "green";
  } else if (overallPercentage >= 60.0 && overallPercentage <= 79.0) {
    fontColor = "orange";
  } else {
    fontColor = "red";
  }

  return (
    <>
      {" "}
      <GoToTop />
      <main id="main" class="main">
        <div class="main-body">
          <div class="row gutters-sm">
            <div class="col-md-12">
              <div class="card mb-3">
                <div class="card-body">
                  <h4
                    style={{
                      backgroundColor: "#212A3E",
                      color: "white",
                      maxWidth: "25vh",
                      padding: "7px",
                    }}
                  >
                    Member Details:
                  </h4>

                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-3 my-2">
                        <Form.Input
                          label="Name"
                          name="name"
                          placeholder="Name"
                          value={name}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-3 my-2">
                        <Form.Input
                          label="Reg no."
                          name="regno"
                          placeholder="Registration number"
                          readOnly
                          value={regno}
                        />
                      </div>

                      <div className="col-lg-3 my-2">
                        <Form.Input
                          error={
                            errors.contact ? { content: errors.contact } : null
                          }
                          label="Contact No."
                          name="contact"
                          placeholder="Contact No."
                          onChange={handleChange}
                          value={contact}
                        />
                      </div>

                      <div className="col-lg-3 my-2">
                        <Form.Field
                          error={errors.dept ? { content: errors.dept } : null}
                          label="Department"
                          style={{ height: "4.8vh" }}
                          name="dept"
                          control="select"
                          value={dept}
                          onChange={handleChange}
                        >
                          <option value="None">--Select--</option>
                          <option value="Member">Member</option>
                          <option value="Coordinator">Coordinator</option>
                          <option value="Board Member">Board Member</option>
                        </Form.Field>
                      </div>
                    </div>

                    <h5 style={{ color: "green" }}>{isSubmitted}</h5>

                    <Button type="submit" color="linkedin">
                      Update
                    </Button>
                  </Form>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <h4
                    style={{
                      backgroundColor: "#212A3E",
                      color: "white",
                      maxWidth: "25vh",
                      padding: "7px",
                    }}
                  >
                    Active Leave:
                  </h4>
                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    size="sm"
                    style={{ fontSize: "12px" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th style={{ textAlign: "left" }}>Reference-ID</th>

                        <th style={{ textAlign: "left" }}>Reason</th>
                        <th style={{ textAlign: "left" }}>From</th>
                        <th style={{ textAlign: "left" }}>To</th>

                        <th style={{ textAlign: "center" }}>Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {leaves.length > 0 ? (
                        leaves.map((user, index) => (
                          <tr key={user.id}>
                            <td style={{ width: "5vh" }}>{index + 1}</td>
                            <td style={{ minWidth: "11vh" }}>#{user.ref}</td>
                            <td
                              style={{
                                minWidth: "14vh",
                              }}
                            >
                              {user.reason}
                            </td>

                            <td style={{ textAlign: "left" }}>
                              {user.enddate && formatTimestamp2(user.startdate)}
                            </td>

                            <td style={{ textAlign: "left" }}>
                              {user.todate && formatTimestamp1(user.todate)}
                            </td>

                            <td
                              style={{
                                color: "green",
                                minWidth: "10vh",
                                textAlign: "center",
                              }}
                            >
                              <b>{user.status}</b>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="10"
                            style={{ textAlign: "center", color: "red" }}
                          >
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <h4
                    style={{
                      backgroundColor: "#212A3E",
                      color: "white",
                      maxWidth: "25vh",
                      padding: "7px",
                    }}
                  >
                    Update Attendance:
                  </h4>

                  <div className="row my-2">
                    <Table responsive striped bordered hover size="sm">
                      <thead>
                        <tr
                          style={{
                            fontSize: "12px",
                            fontWeight: "normal",
                          }}
                        >
                          <th style={{ textAlign: "left" }}>Month</th>

                          <th style={{ textAlign: "center" }}>Status</th>

                          <th style={{ textAlign: "center" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody style={{ fontSize: "12px" }}>
                        <tr>
                          <td style={{ textAlign: "left" }}>
                            <select
                              className="form-control"
                              value={updateStatus}
                              onChange={(e) => setUpdatedStatus(e.target.value)}
                            >
                              <option value="">--Select--</option>
                              <option value="Jan23a">January 2023 (A)</option>
                              <option value="Jan23b">January 2023 (B)</option>
                              <option value="Feb23a">February 2023 (A)</option>
                              <option value="Feb23b">February 2023 (B)</option>
                              <option value="Mar23a">March 2023 (A)</option>
                              <option value="Mar23b">March 2023 (B)</option>
                              <option value="Apr23a">April 2023 (A)</option>
                              <option value="Apr23b">April 2023 (B)</option>
                              <option value="May23a">May 2023 (A)</option>
                              <option value="May23b">May 2023 (B)</option>

                              <option value="June2023A">June 2023 (A)</option>
                              <option value="June2023B">June 2023 (B)</option>
                              <option value="July2023A">July 2023 (A)</option>
                              <option value="July2023B">July 2023 (B)</option>

                              <option value="August2023A">
                                August 2023 (A)
                              </option>
                              <option value="August2023B">
                                August 2023 (B)
                              </option>
                              <option value="September2023A">
                                September 2023 (A)
                              </option>
                              <option value="September2023B">
                                September 2023 (B)
                              </option>

                              <option value="October2023A">
                                October 2023 (A)
                              </option>
                              <option value="October2023B">
                                October 2023 (B)
                              </option>

                              <option value="November2023A">
                                November 2023 (A)
                              </option>
                              <option value="November2023B">
                                November 2023 (B)
                              </option>

                              <option value="December2023A">
                                December 2023 (A)
                              </option>
                              <option value="December2023B">
                                December 2023 (B)
                              </option>
                              <option value="January2024A">
                                January 2024 (A)
                              </option>
                              <option value="January2024B">
                                January 2024 (B)
                              </option>
                            </select>
                          </td>

                          <td style={{ textAlign: "left" }}>
                            <select
                              className="form-control"
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                            >
                              <option value="">--Select--</option>
                              <option value="Present">Present</option>
                              <option value="Absent">Absent</option>
                            </select>
                          </td>

                          <td style={{ width: "13vh", textAlign: "center" }}>
                            <Button type="submit" onClick={handleSubmitU}>
                              Save
                            </Button>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ textAlign: "left" }}>
                            <select
                              className="form-control"
                              value={updateDesc}
                              onChange={(e) => setUpdatedDesc(e.target.value)}
                            >
                              <option value="">--Select--</option>
                              <option value="Jan23af">January 2023 (A)</option>
                              <option value="Jan23bf">January 2023 (B)</option>
                              <option value="Feb23af">February 2023 (A)</option>
                              <option value="Feb23bf">February 2023 (B)</option>
                              <option value="Mar23af">March 2023 (A)</option>
                              <option value="Mar23bf">March 2023 (B)</option>
                              <option value="Apr23af">April 2023 (A)</option>
                              <option value="Apr23bf">April 2023 (B)</option>
                              <option value="May23af">May 2023 (A)</option>
                              <option value="May23bf">May 2023 (B)</option>

                              <option value="June2023ADesc">
                                June 2023 (A)
                              </option>
                              <option value="June2023BDesc">
                                June 2023 (B)
                              </option>
                              <option value="July2023ADesc">
                                July 2023 (A)
                              </option>
                              <option value="July2023BDesc">
                                July 2023 (B)
                              </option>
                              <option value="August2023ADesc">
                                August 2023 (A)
                              </option>
                              <option value="August2023BDesc">
                                August 2023 (B)
                              </option>
                              <option value="September2023ADesc">
                                September 2023 (A)
                              </option>
                              <option value="September2023BDesc">
                                September 2023 (B)
                              </option>

                              <option value="October2023ADesc">
                                October 2023 (A)
                              </option>
                              <option value="October2023BDesc">
                                October 2023 (B)
                              </option>

                              <option value="November2023ADesc">
                                November 2023 (A)
                              </option>
                              <option value="November2023BDesc">
                                November 2023 (B)
                              </option>

                              <option value="December2023ADesc">
                                December 2023 (A)
                              </option>
                              <option value="December2023BDesc">
                                December 2023 (B)
                              </option>

                              <option value="January2024ADesc">
                                January 2024 (A)
                              </option>
                              <option value="January2024BDesc">
                                January 2024 (B)
                              </option>
                            </select>
                          </td>

                          <td style={{ textAlign: "left" }}>
                            <input
                              className="form-control"
                              value={desc}
                              onChange={(e) => setDesc(e.target.value)}
                            ></input>
                          </td>

                          <td style={{ width: "13vh", textAlign: "center" }}>
                            <Button type="submit" onClick={handleSubmitD}>
                              Save
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <div className="row"></div>
                </div>
              </div>
              <div class="card mb-3">
                <div class="card-body">
                  <h4
                    style={{
                      backgroundColor: "#212A3E",
                      color: "white",
                      maxWidth: "25vh",
                      padding: "7px",
                    }}
                  >
                    Attendance:
                  </h4>

                  <h4>
                    Attendance Percentage:{" "}
                    <b style={{ color: fontColor }}>{overallPercentage}%</b>
                    <br></br>
                    {overallPercentage >= 60 ? (
                      <i style={{ color: "green", fontSize: "11px" }}>
                        (Eligible for certification)
                      </i>
                    ) : (
                      <i style={{ color: "red", fontSize: "11px" }}>
                        (Not eligible for certification)
                      </i>
                    )}
                  </h4>

                  <Table
                    responsive
                    hover
                    striped
                    bordered
                    size="sm"
                    style={{ fontSize: "12px" }}
                  >
                    <thead
                      style={{ backgroundColor: "#1f88be", color: "#fff" }}
                    >
                      <tr>
                        <th scope="col">Month/Year</th>
                        <th scope="col">Week</th>
                        <th scope="col">Description</th>
                        <th scope="col" style={{ textAlign: "center" }}>
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: "12px" }}>
                      {Jan23a !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>January 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st January 2023 - 15th January 2023
                            </td>

                            <td
                              style={{
                                minWidth: "20vh",
                              }}
                            >
                              {Jan23af}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color: Jan23a === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{Jan23a}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {Jan23b !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>January 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th January 2023 - 30th January 2023
                            </td>

                            <td
                              style={{
                                minWidth: "20vh",
                              }}
                            >
                              {Jan23bf}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color: Jan23b === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{Jan23b}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {Feb23a !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>February 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st February 2023 - 15th February 2023
                            </td>

                            <td
                              style={{
                                minWidth: "20vh",
                                color: "green",
                              }}
                            >
                              <b>{Feb23af}</b>
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color: Feb23a === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{Feb23a}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {Feb23b !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>February 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th February 2023 - 28th February 2023
                            </td>

                            <td
                              style={{
                                minWidth: "20vh",
                                color: "green",
                              }}
                            >
                              <b>{Feb23bf}</b>
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color: Feb23b === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{Feb23b}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {Mar23a !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>March 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st March 2023 - 15th March 2023
                            </td>

                            <td
                              style={{
                                minWidth: "20vh",
                              }}
                            >
                              {Mar23af}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color: Mar23a === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{Mar23a}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {Mar23b !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>March 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th March 2023 - 30th March 2023
                            </td>

                            <td
                              style={{
                                minWidth: "20vh",
                                color: "green",
                              }}
                            >
                              <b>{Mar23bf}</b>
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color: Mar23b === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{Mar23b}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {Apr23a !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>April 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st April 2023 - 15th April 2023
                            </td>

                            <td
                              style={{
                                minWidth: "20vh",
                              }}
                            >
                              {Apr23af}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color: Apr23a === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{Apr23a}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {Apr23b !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>April 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th April 2023 - 30th April 2023
                            </td>

                            <td
                              style={{
                                minWidth: "20vh",
                              }}
                            >
                              {Apr23bf}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color: Apr23b === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{Apr23b}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {May23a !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>May 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st May 2023 - 15th May 2023
                            </td>

                            <td
                              style={{
                                minWidth: "20vh",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              {May23af}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color: May23a === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{May23a}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {May23b !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>May 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th May 2023 - 30th May 2023
                            </td>

                            <td
                              style={{
                                minWidth: "20vh",
                                color: "green",
                                fontWeight: "bold",
                              }}
                            >
                              {May23bf}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color: May23b === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{May23b}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {June2023A !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>June 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st June 2023 - 15th June 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {June2023ADesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  June2023A === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{June2023A}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {June2023B !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>June 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th June 2023 - 30th June 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {June2023BDesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  June2023B === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{June2023B}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {July2023A !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>July 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st July 2023 - 15th July 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {July2023ADesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  July2023A === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{July2023A}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {July2023B !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>July 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th July 2023 - 30th July 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {July2023BDesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  July2023B === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{July2023B}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {August2023A !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>August 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st August 2023 - 15th August 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {August2023ADesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  August2023A === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{August2023A}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {August2023B !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>August 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th August 2023 - 30th August 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {August2023BDesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  August2023B === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{August2023B}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {September2023A !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>September 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st September 2023 - 15th August 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {September2023ADesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  September2023A === "Present"
                                    ? "green"
                                    : "red",
                              }}
                            >
                              <b>{September2023A}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {September2023B !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>September 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th September 2023 - 30th September 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {September2023BDesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  September2023B === "Present"
                                    ? "green"
                                    : "red",
                              }}
                            >
                              <b>{September2023B}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {October2023A !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>October 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st October 2023 - 15th October 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {October2023ADesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  October2023A === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{October2023A}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {October2023B !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>October 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th October 2023 - 30th October 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {October2023BDesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  October2023B === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{October2023B}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {November2023A !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>November 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st November 2023 - 15th November 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {November2023ADesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  November2023A === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{November2023A}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {November2023B !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>November 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th November 2023 - 30th November 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {November2023BDesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  November2023B === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{November2023B}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {December2023A !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>December 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st December 2023 - 15th December 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {December2023ADesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  December2023A === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{December2023A}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {December2023B !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>December 2023</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th December 2023 - 30th December 2023
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {December2023BDesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  December2023B === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{December2023B}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {January2024A !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>January 2024</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              1st January 2024 - 15th January 2024
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {January2024ADesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  January2024A === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{January2024A}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}

                      {January2024B !== undefined ? (
                        <>
                          <tr>
                            <td style={{ minWidth: "14vh" }}>
                              <b>January 2024</b>
                            </td>
                            <td style={{ minWidth: "29vh" }}>
                              15th January 2024 - 30th January 2024
                            </td>

                            <td style={{ minWidth: "20vh" }}>
                              {January2024BDesc}
                            </td>
                            <td
                              style={{
                                minWidth: "12vh",
                                textAlign: "center",
                                color:
                                  January2024B === "Present" ? "green" : "red",
                              }}
                            >
                              <b>{January2024B}</b>
                            </td>
                          </tr>
                        </>
                      ) : null}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </main>
    </>
  );
};

export default EditAtten;
