import React, { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { useNavigate, useLocation } from "react-router-dom";
import { collection, addDoc, doc, serverTimestamp } from "firebase/firestore";
import { Form, Button } from "semantic-ui-react";
function AddDonation() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const event = params.get("event");
  const eventname = params.get("event");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    amount: "",
    status: "",
    kit: "",
    hometown: "",
    date: serverTimestamp(),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Reference the specific document within the Donations collection
      const docRef = doc(db, "Donations", event);

      // Add the form data to the "event" subcollection within this document
      const eventRef = collection(docRef, "Donators");
      await addDoc(eventRef, formData);

      navigate(-1);
      console.log("DAta Saved");

      // Reset form
      setFormData({
        name: "",
        phone: "",
        email: "",
        amount: "",
        status: "",
        kit: "",
        hometown: "",
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <main id="main" class="main">
        <h5>{eventname == "FoodDrive2024Delhi" ? "Flood Donation Drive 2024" : eventname}</h5>

        <Form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-4 my-2">
              <Form.Input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="col-lg-4 my-2">
              <Form.Input
                type="text"
                name="phone"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>

            <div className="col-lg-4 my-2">
              <Form.Input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>

            <div className="col-lg-4 my-2">
              <Form.Input
                type="number"
                name="amount"
                placeholder="Amount"
                value={formData.amount}
                onChange={handleChange}
                required
              />
            </div>

            <div className="col-lg-4 my-2">
              <Form.Field
                name="status"
                placeholder="Status"
                value={formData.status}
                onChange={handleChange}
                required
                control="select"
              >
                <option>--Select--</option>
                <option value="Approved">Approved</option>
                <option value="Pending">Pending</option>
              </Form.Field>
            </div>

            <div className="col-lg-4 my-2">
              <Form.Input
                type="text"
                name="kit"
                placeholder="Kit"
                value={formData.kit}
                onChange={handleChange}
              />
            </div>

            <div className="col-lg-4 my-2">
              <Form.Input
                type="text"
                name="hometown"
                placeholder="Hometown"
                value={formData.hometown}
                onChange={handleChange}
              />
            </div>

            <div className="col-lg-4 my-2">
              <Button type="submit">Submit</Button>
            </div>
          </div>
        </Form>
      </main>
    </>
  );
}

export default AddDonation;
