import React, {useState} from "react";
import {db} from "../../firebase-config";
import {Button, Modal, Form} from "semantic-ui-react";
import "react-quill/dist/quill.snow.css";

const ModalEdit = ({
  open,
  setOpen,
  Ename,
  eventdate,
  Evenue,
  registration,
  certificate,

  id,
}) => {
  const [updateName, setName] = useState(Ename);
  const [updateDate, setDate] = useState(eventdate);
  const [updateVenue, setVenue] = useState(Evenue);
  const [updateReg, setReg] = useState(registration);
  const [updateCertificate, setCertificate] = useState(certificate);

  const handleSubmit = async () => {
    try {
      // Create a new document in Firestore collection
      await db.collection("Events").doc(id).update({
        Ename: updateName,
        eventdate: updateDate,
        Evenue: updateVenue,
        registration: updateReg,
        certificate: updateCertificate,
      });

      // Reset form fields and close the modal

      setOpen(false);
    } catch (error) {
      console.log("Error updating applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{width: "100%", height: "100%", backgroundColor: "transparent"}}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Update Event Details
            </h5>
            <button
              type="button"
              onClick={() => setOpen(false)}
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <Form>
                <Form.Input
                  placeholder="Event Name"
                  label="Event Name"
                  required
                  onChange={(e) => setName(e.target.value)}
                  defaultValue={Ename}
                />

                <br></br>
                <Form.Input
                  placeholder="Event Date"
                  label="Event Date"
                  type="datetime-local"
                  required
                  onChange={(e) => setDate(e.target.value)}
                  defaultValue={eventdate}
                />

                <br></br>
                <Form.Input
                  placeholder="Event Venue"
                  label="Event Venue"
                  type="text"
                  onChange={(e) => setVenue(e.target.value)}
                  defaultValue={Evenue}
                />

                <br></br>
                <Form.Field
                  required
                  control="select"
                  defaultValue={registration}
                  onChange={(e) => setReg(e.target.value)}
                  placeholder="Event Venue"
                  label="Event Venue"
                >
                  <option value="">--Select--</option>
                  <option value="Open">Registration Open</option>
                  <option value="Closed">Registration Closed</option>
                </Form.Field>

                <Form.Field
                  required
                  control="select"
                  defaultValue={certificate}
                  onChange={(e) => setCertificate(e.target.value)}
                  placeholder="Event Certificate"
                  label="Event Certificate"
                >
                  <option value="">--Select--</option>
                  <option value="Available">Available</option>
                  <option value="Not Available">Not Available</option>
                </Form.Field>
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              color="blue"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalEdit;
