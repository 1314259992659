import React, { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { Button } from "semantic-ui-react";
import numberToWords from "number-to-words";
import { useNavigate, Link } from "react-router-dom";
import GoToTop from "../../GoToTop";
import { onSnapshot } from "firebase/firestore";

import { Table } from "react-bootstrap";
function Finance() {
  const [type, setType] = useState("Deposit");
  const [purpose, setPurspose] = useState("");
  const [ddate, setDate] = useState("");
  const [amount, setAmount] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
    const transaction = {
      purpose,
      ddate,
      type,
      amount: parseFloat(amount),
    };
    db.collection("finance")
      .add(transaction)
      .then(() => {
        setType("Deposit");
        setAmount(0);
      })
      .catch((error) => {
        console.error("Error adding transaction: ", error);
      });
  };

  const [photos, setPhotos] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsub = onSnapshot(
      db.collection("finance").orderBy("date", "desc"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setPhotos(list);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  let FD2024Delhi = "FoodDrive2024Delhi";
  let FD2021 = "FoodDrive2021";
  let Hepah = "Hepah_From_Welfare_to_Well_being";
  let MC2022 = "Medical_CheckUp_2022";
  let CDJN2023 = "Childrens_day_2023";
  let PRABHATI2021 = "Prabhati_2021";

  let IDCards = "ID_Cards";
  let TShirts = "TShirts";
  return (
    <>
      <GoToTop />
      <main id="main" class="main">
        <div className="section-title">
          <h2
            style={{
              fontWeight: "bold",
              fontFamily: "Montserrat, sans-serif",
              color: "#212A3E",
            }}
          >
            Finance
          </h2>
        </div>
        <br></br>

        {/* <Link
          className="btn btn-warning"
          style={{ float: "right", marginTop: "-39px" }}
          to="/pending_pays"
        >
          Pending Payments
        </Link> */}

        <div className="row">
          <div className="col-lg-4 my-1">
            <div
              onClick={() =>
                navigate(
                  `/event_funds/?event=${FD2024Delhi}&n=Flood Donation Drive 2024`
                )
              }
              className="ui card"
              style={{
                padding: "10px",
                width: "100%",
                cursor: "pointer",
                backgroundColor: "#53a4cf",
                color: "white",
              }}
            >
              <div className="row">
                <div className="col-10">Flood Donation Drive 2024</div>
                <div
                  className="col-2"
                  style={{
                    borderLeft: "1px solid #c6e7f7",
                    textAlign: "center",
                  }}
                >
                  <h4>
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 my-1">
            <div
              onClick={() =>
                navigate(
                  `/event_funds/?event=${CDJN2023}&n=Childrens Day - Project Jivanam`
                )
              }
              className="ui card"
              style={{
                padding: "10px",
                width: "100%",
                backgroundColor: "#53a4cf",
                color: "white",
                cursor: "pointer",
              }}
            >
              <div className="row">
                <div className="col-10">Childrens Day - Jivanam</div>
                <div
                  className="col-2"
                  style={{
                    borderLeft: "1px solid #c6e7f7",
                    textAlign: "center",
                  }}
                >
                  <h4>
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 my-1">
            <div
              onClick={() =>
                navigate(`/event_funds_flood_distribution_drive_2023`)
              }
              className="ui card"
              style={{
                padding: "10px",
                width: "100%",
                backgroundColor: "#53a4cf",
                color: "white",
                cursor: "pointer",
              }}
            >
              <div className="row">
                <div className="col-10">
                  Flood Distribution Drive - Project Jivanam
                </div>
                <div
                  className="col-2"
                  style={{
                    borderLeft: "1px solid #c6e7f7",
                    textAlign: "center",
                  }}
                >
                  <h4>
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 my-1">
            <div
              onClick={() =>
                navigate(
                  `/event_funds/?event=${Hepah}&n=Hepah : From Welfare to Well-being`
                )
              }
              className="ui card"
              style={{
                padding: "10px",
                width: "100%",
                backgroundColor: "#53a4cf",
                color: "white",
                cursor: "pointer",
              }}
            >
              <div className="row">
                <div className="col-10">Hepah : From Welfare to Well-being</div>
                <div
                  className="col-2"
                  style={{
                    borderLeft: "1px solid #c6e7f7",
                    textAlign: "center",
                  }}
                >
                  <h4>
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 my-1">
            <div
              onClick={() =>
                navigate(
                  `/event_funds/?event=${PRABHATI2021}&n=Prabhati - A Dawn of a Vital Outlook`
                )
              }
              className="ui card"
              style={{
                padding: "10px",
                width: "100%",
                backgroundColor: "#53a4cf",
                color: "white",
                cursor: "pointer",
              }}
            >
              <div className="row">
                <div className="col-10">
                  Prabhati - A Dawn of a Vital Outlook
                </div>
                <div
                  className="col-2"
                  style={{
                    borderLeft: "1px solid #c6e7f7",
                    textAlign: "center",
                  }}
                >
                  <h4>
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 my-1">
            <div
              onClick={() =>
                navigate(
                  `/event_funds/?event=${MC2022}&n=Wellness Drive: Medical and Health CheckUp`
                )
              }
              className="ui card"
              style={{
                padding: "10px",
                width: "100%",
                backgroundColor: "#53a4cf",
                cursor: "pointer",
                color: "white",
              }}
            >
              <div className="row">
                <div className="col-10">WD : Medical & Health CheckUp</div>
                <div
                  className="col-2"
                  style={{
                    borderLeft: "1px solid #c6e7f7",
                    textAlign: "center",
                  }}
                >
                  <h4>
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 my-1">
            <div
              onClick={() =>
                navigate(
                  `/event_funds/?event=${FD2021}&n=Wellness Drive-Food Distribution 2021`
                )
              }
              className="ui card"
              style={{
                padding: "10px",
                width: "100%",
                backgroundColor: "#53a4cf",
                color: "white",
                cursor: "pointer",
              }}
            >
              <div className="row">
                <div className="col-10">
                  Wellness Drive-Food Distribution 2021
                </div>
                <div
                  className="col-2"
                  style={{
                    borderLeft: "1px solid #c6e7f7",
                    textAlign: "center",
                  }}
                >
                  <h4>
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 my-1">
            <div
              onClick={() =>
                navigate(`/www_funds/?type=${IDCards}&n=WWW ID-Cards`)
              }
              className="ui card"
              style={{
                padding: "10px",
                width: "100%",
                backgroundColor: "orange",
                color: "white",
                cursor: "pointer",
              }}
            >
              <div className="row">
                <div className="col-10">WWW ID-Cards</div>
                <div
                  className="col-2"
                  style={{
                    borderLeft: "1px solid #c6e7f7",
                    textAlign: "center",
                  }}
                >
                  <h4>
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4 my-1">
            <div
              onClick={() =>
                navigate(`/www_funds/?type=${TShirts}&n=WWW T-Shirts`)
              }
              className="ui card"
              style={{
                padding: "10px",
                width: "100%",
                backgroundColor: "red",
                color: "white",
                cursor: "pointer",
              }}
            >
              <div className="row">
                <div className="col-10">WWW T-shirts</div>
                <div
                  className="col-2"
                  style={{
                    borderLeft: "1px solid #c6e7f7",
                    textAlign: "center",
                  }}
                >
                  <h4>
                    <i class="fa fa-paper-plane" aria-hidden="true"></i>
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Table responsive striped bordered hover size="sm">
            <thead>
              <tr
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  backgroundColor: "#1f88be",
                  color: "white",
                }}
              >
                <th style={{ textAlign: "center" }}>#</th>

                <th style={{ textAlign: "left" }}>Purpose</th>
                <th style={{ textAlign: "center" }}>Date</th>

                <th style={{ textAlign: "left" }}>Amount raised</th>
                <th style={{ textAlign: "left" }}>Balance</th>
                <th style={{ textAlign: "center" }}>Action</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: "12px" }}>
              {photos &&
                photos.map((item, index) => (
                  <tr>
                    <td style={{ textAlign: "center", width: "5vh" }}>
                      {index + 1}
                    </td>

                    <td
                      style={{
                        textAlign: "left",
                      }}
                    >
                      {item.purpose}
                    </td>
                    <td
                      style={{
                        textAlign: "center",

                        fontWeight: "bold",
                      }}
                    >
                      {item.date}
                    </td>

                    <td
                      style={{
                        textAlign: "left",

                        fontWeight: "bold",
                        color: "green",
                      }}
                    >
                      {item.amount}{" "}
                      <span
                        style={{
                          color: "grey",
                        }}
                      >
                        [ {numberToWords.toWords(item.amount)} ]
                      </span>
                    </td>

                    <td
                      style={{
                        textAlign: "left",

                        fontWeight: "bold",
                        color: item.balance === "0" ? "red" : "black",
                      }}
                    >
                      {item.balance}{" "}
                      <span
                        style={{
                          color: "grey",
                        }}
                      >
                        [ {numberToWords.toWords(item.balance)} ]
                      </span>
                    </td>

                    <td style={{ textAlign: "center", width: "10vh" }}>
                      <Button
                        style={{ marginTop: "2px" }}
                        size="small"
                        onClick={() => navigate(`/editfinance/${item.id}`)}
                      >
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          {/* <div className="col-lg-4">
          {" "}
          <Form onSubmit={handleSubmit}>
            <Form.Input
              label="Purpose"
              type="text"
              value={purpose}
              onChange={(e) => setPurspose(e.target.value)}
            />

            <Form.Input
              label="Date"
              type="date"
              value={ddate}
              onChange={(e) => setDate(e.target.value)}
            />

            <Form.Field
              label="Type"
              control="select"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="Deposit">Deposit</option>
              <option value="Withdrawal">Withdrawal</option>
            </Form.Field>

            <Form.Input
              label="Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />

            <Button type="submit">Add Transaction</Button>
          </Form>
        </div> */}
        </div>
      </main>
    </>
  );
}

export default Finance;
