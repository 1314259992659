import React, { useState, useEffect } from "react";
import { db } from "../../firebase-config";
import { useNavigate, useLocation } from "react-router-dom";
import PendingPay from "../Donations/PendingPay";
import { Button } from "semantic-ui-react";
import { onSnapshot } from "firebase/firestore";
import { Table } from "react-bootstrap";
function EventFunds() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const event = params.get("event");
  const eventname = params.get("n");
  const navigate = useNavigate();
  const [photos, setPhotos] = useState([]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const unsub = onSnapshot(
      db
        .collection("Donations")
        .doc(event)
        .collection("Donators")
        .orderBy("date", "desc"),
      (snapshot) => {
        let list = [];
        snapshot.docs.forEach((doc) => {
          list.push({ id: doc.id, ...doc.data() });
        });
        setPhotos(list);
        setLoading(false);
      },

      (error) => {
        console.error(error);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  const formatTimestamp = (date) => {
    const datee = date.toDate();
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return datee.toLocaleString("en-US", options);
  };

  //////////////////////////////////////////////////
  const [totalDonation, setTotalDonation] = useState(0);

  useEffect(() => {
    const fetchTotalDonation = async () => {
      try {
        const donationsRef = db
          .collection("Donations")
          .doc(event)
          .collection("Donators");

        const donationsSnapshot = await donationsRef.get();

        let sum = 0;

        donationsSnapshot.forEach((donationDoc) => {
          const { amount } = donationDoc.data();
          sum += +amount;
        });

        setTotalDonation(sum);
      } catch (error) {
        console.error("Error fetching total donation:", error);
      }
    };

    fetchTotalDonation();
  }, []);
  return (
    <>
      <main id="main" class="main">
        <Button
          style={{ float: "right", marginTop: "-4px" }}
          color="linkedin"
          size="tiny"
          onClick={() => navigate(`/add_Donation/?event=${event}`)}
        >
          Add Funds
        </Button>

        <PendingPay />
    
        <h5>{eventname}</h5>
        <Table responsive striped bordered hover size="sm">
          <thead>
            <tr
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                backgroundColor: "#1f88be",
                color: "white",
              }}
            >
              <th style={{ textAlign: "center" }}>#</th>

              <th style={{ textAlign: "left" }}>Name</th>
              <th style={{ textAlign: "left" }}>Contact</th>
              <th style={{ textAlign: "left" }}>Email</th>
              <th style={{ textAlign: "left" }}>Hometown</th>
              <th style={{ textAlign: "left" }}>Date</th>

              <th style={{ textAlign: "right", paddingRight: "10px" }}>
                Amount
              </th>
            </tr>
          </thead>
          <tbody style={{ fontSize: "12px" }}>
            {photos &&
              photos.map((item, index) => (
                <tr>
                  <td style={{ textAlign: "center", width: "5vh" }}>
                    {index + 1}
                  </td>

                  <td
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {item.name}
                  </td>

                  <td
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {item.phone}
                  </td>

                  <td
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {item.email}
                  </td>
                  <td
                    style={{
                      textAlign: "left"
                    }}
                  >
                    {item.hometown}
                  </td>

                  <td
                    style={{
                      textAlign: "left",
                      color: "grey",
                      fontWeight: "bold",
                    }}
                  >
                    {item.date && formatTimestamp(item.date)}
                  </td>

            

                  <td
                    style={{
                      textAlign: "right",
                      paddingRight: "10px",
                      fontWeight: "bold",
                      color: "green",
                    }}
                  >
                    ₹ {item.amount}.00
                    <span
                      style={{
                        color: "grey",
                      }}
                    ></span>
                  </td>
                </tr>
              ))}
            <tr>
              <td colSpan="5"></td>

              <td style={{ textAlign: "right" }}>
                <b style={{ color: "red" }}>Total:</b>
              </td>
              <td
                style={{
                  backgroundColor: "#3a7824",
                  color: "white",
                  fontSize: "15px",
                  textAlign: "right",
                }}
              >
                ₹ <b>{totalDonation}</b>.00
              </td>
            </tr>
          </tbody>
        </Table>
      </main>
    </>
  );
}

export default EventFunds;
