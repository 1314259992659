import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { db, auth } from "../../../firebase-config";
import GoToTop from "../../../GoToTop";
import DeptCount from "../../Components/DeptCounts";
import DeptCount2 from "../../Components/Dept2Count";
import MemberSearch from "../../Components/MemberSearch";
import MaleCount from "../../Components/MaleCount";
import FemaleCount from "../../Components/FemaleCount";
import StateCounts from "../../Components/StateCount";
import BoardMem from "../../Components/BoardMem";
import BloodGroups from "../../Components/BloodCount";
import MemberCountImg from "./student.gif";
import MembersCount from "../../Components/MembersCount";
import TMembersCount from "../../Components/TMembersCount";

const Home = () => {
  //For visibility of the page

  const [userData, setUserData] = useState(null);

  const userId = auth.currentUser.uid;

  const [greeting, setGreeting] = useState("");

  useEffect(() => {
    const currentTime = new Date().getHours();

    if (currentTime >= 3 && currentTime < 12) {
      setGreeting("Good Morning");
    } else if (currentTime >= 12 && currentTime < 17) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  }, []);

  useEffect(() => {
    db.collection("members")
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          setUserData(doc.data());
        } else {
          console.log("No such user!");
        }
      })
      .catch((error) => {
        console.log("Error getting user:", error);
      });
  }, [userId]);

  if (!userData) {
    return <div>Loading user data...</div>;
  }

  return (
    <>
      <GoToTop />

      <main id="main" class="main">
        {/* Welcome box */}
        <div style={{ position: "relative" }}>
          <div class="pagetitle">
            <p
              style={{
                fontSize: "20px",
                color: "#1F88BE",
                fontWeight: "bold",
              }}
            >
              {greeting}, {userData.name.split(" ")[0]}!
            </p>
            <p style={{ color: "grey", marginTop: "-2.5vh", fontSize: "12px" }}>
              We're thrilled to see you again. Your dashboard is ready to go!
              All systems are running smoothly!
            </p>
          </div>

          <DeptCount />

          <div className="my-3">
            <b style={{ color: "grey" }}>Second Preference:</b>
            <DeptCount2 />
          </div>

          <div className="row">
            <div className="col-lg-3">
              <Link to="/www_members_details_">
                <div
                  class="card info-card revenue-card"
                  style={{ backgroundColor: "white" }}
                >
                  <div class="card-body" style={{ paddingTop: "0px" }}>
                    <div
                      class="d-flex align-items-center"
                      style={{ marginBottom: "-22px" }}
                    >
                      <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <img
                          src={MemberCountImg}
                          class="img-fluid"
                          style={{ width: "10vh" }}
                          alt="..."
                        />
                      </div>
                      <div class="ps-3 my-3">
                        <h2 style={{ marginBottom: "2px" }}>
                          <TMembersCount />
                        </h2>
                        <span class="text-success small pt-1 fw-bold">
                          Total Members
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              {/* <MemberSearch /> */}
              <br></br>
            </div>

            <div className="col-lg-3">
              <Link to="/www_members_details_">
                <div
                  class="card info-card revenue-card"
                  style={{ backgroundColor: "white" }}
                >
                  <div class="card-body" style={{ paddingTop: "0px" }}>
                    <div
                      class="d-flex align-items-center"
                      style={{ marginBottom: "-22px" }}
                    >
                      <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <img
                          src={MemberCountImg}
                          class="img-fluid"
                          style={{ width: "10vh" }}
                          alt="..."
                        />
                      </div>
                      <div class="ps-3 my-3">
                        <h2 style={{ marginBottom: "2px" }}>
                          <MembersCount />
                        </h2>
                        <span class="text-success small pt-1 fw-bold">
                          Active Members
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              {/* <MemberSearch /> */}
              <br></br>
            </div>

            <div className="col-lg-3">
              <Link to="/www_members_details_">
                <div
                  class="card info-card revenue-card"
                  style={{ backgroundColor: "white" }}
                >
                  <div class="card-body" style={{ paddingTop: "0px" }}>
                    <div
                      class="d-flex align-items-center"
                      style={{ marginBottom: "-22px" }}
                    >
                      <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <img
                          src={MemberCountImg}
                          class="img-fluid"
                          style={{ width: "10vh" }}
                          alt="..."
                        />
                      </div>
                      <div class="ps-3 my-3">
                        <h2 style={{ marginBottom: "2px" }}>
                          <MaleCount />
                        </h2>
                        <span class="text-success small pt-1 fw-bold">
                          Male Members
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              {/* <MemberSearch /> */}
              <br></br>
            </div>

            <div className="col-lg-3">
              <Link to="/www_members_details_">
                <div
                  class="card info-card revenue-card"
                  style={{ backgroundColor: "white" }}
                >
                  <div class="card-body" style={{ paddingTop: "0px" }}>
                    <div
                      class="d-flex align-items-center"
                      style={{ marginBottom: "-22px" }}
                    >
                      <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                        <img
                          src={MemberCountImg}
                          class="img-fluid"
                          style={{ width: "10vh" }}
                          alt="..."
                        />
                      </div>
                      <div class="ps-3 my-3">
                        <h2 style={{ marginBottom: "2px" }}>
                          <FemaleCount />
                        </h2>
                        <span class="text-success small pt-1 fw-bold">
                          Female Members
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              {/* <MemberSearch /> */}
              <br></br>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div
                className="col-lg-5 my-2"
                style={{
                  backgroundColor: "white",
                  padding: "12px",
                }}
              >
                <b>Search Member Details:</b>

                <MemberSearch />
              </div>
              <div
                className="col-lg-7 my-2"
                style={{
                  backgroundColor: "white",
                  padding: "12px 5px 5px 5px",
                  borderLeft: "4px solid #f6f9ff",
                }}
              >
                <b style={{ marginLeft: "10px" }}>Members in India:</b>
                <br></br> <br></br>
                <StateCounts />
              </div>
            </div>
          </div>

          <br></br>
          <br></br>

          <b style={{ color: "grey", marginTop: "10px" }}>Board Members:</b>
          <div
            className="content"
            style={{
              backgroundColor: "white",
              padding: "17px 10px 10px 10px",
            }}
          >
            <div style={{ overflowY: "scroll", height: "65vh" }}>
              <BoardMem />
            </div>
          </div>
        </div>

        <br></br>
      </main>
    </>
  );
};

export default Home;
