import React, {useState, useEffect} from "react";
import {db} from "../../firebase-config";
import {Button, Modal, Form} from "semantic-ui-react";
import {collection, doc, getDoc, getDocs} from "firebase/firestore";

const ModalMAdd = ({open, setOpen, id}) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionData, setSelectedOptionData] = useState(null);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    const querySnapshot = await getDocs(collection(db, "Events")); // Replace "Events" with the actual name of your collection

    const optionList = querySnapshot.docs.map((doc) => {
      return {value: doc.id, label: doc.data().Ename}; // Modify the value and label properties according to your data structure
    });

    setOptions(optionList);
  };

  const handleSelectChange = async (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    const docRef = doc(db, "Events", selectedValue); // Replace "Events" with the actual name of your collection

    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      setSelectedOptionData({id: docSnapshot.id, ...docSnapshot.data()});
    }
  };

  const handleSubmit = async () => {
    try {
      // Create a new document in Firestore collection
      db.collection("RegisteredEvents")
        .doc(id)
        .collection("Events")
        .doc(selectedOptionData.Ename)
        .set({
          Ename: selectedOptionData.Ename,
          eventdate: selectedOptionData.eventdate,
          Evenue: selectedOptionData.Evenue,
          Edate: selectedOptionData.postedOn,
          status: "--",
          certificate: "",
        })
        .then(() => {
          db.collection("RegisteredEvents").doc(id).update({
            eventName: selectedOptionData.Ename,
          });
        });

      // Reset form fields and close the modal

      setOpen(false);
    } catch (error) {
      console.log("Error updating applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{width: "100%", height: "100%", backgroundColor: "transparent"}}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Add Event
            </h5>
            <button
              type="button"
              onClick={() => setOpen(false)}
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <Form>
                <Form.Field
                  required
                  control="select"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  placeholder="Event Name"
                  label="Event Name"
                >
                  <option value="">--Select--</option>
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Field>

                {selectedOptionData && (
                  <>
                    <Form.Input
                      placeholder="Event Venue"
                      label="Event Venue"
                      value={selectedOptionData.Evenue}
                    />
                    <Form.Input
                      placeholder="Event Venue"
                      label="Event Venue"
                      value={selectedOptionData.eventdate}
                    />
                  </>
                )}
              </Form>
            </div>
          </div>
          <div class="modal-footer">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              color="blue"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalMAdd;
