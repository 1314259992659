import React, {useState} from "react";
import {db} from "../../firebase-config";
import {Button, Modal, Form} from "semantic-ui-react";
import "react-quill/dist/quill.snow.css";
import {useParams} from "react-router-dom";
import {Alert} from "react-bootstrap";
const ModalMEdit = ({
  open,
  setOpen,
  Ename,
  eventdate,
  Evenue,
  certificate,
  status,
}) => {
  const [updateCertificate, setCertificate] = useState(certificate);
  const [updateStatus, setStatus] = useState(certificate);
  const [msg, setMsg] = useState("");
  const {id} = useParams();

  const handleSubmit = async () => {
    try {
      setMsg("Updating...");
      // Create a new document in Firestore collection
      await db
        .collection("RegisteredEvents")
        .doc(id)
        .collection("Events")
        .doc(Ename)
        .update({
          certificate: updateCertificate,
          status: updateStatus,
        });

      // Reset form fields and close the modal
      setMsg("Data Updated");
      setStatus("");
      setCertificate("");
      setTimeout(() => {
        setOpen(false);
        setMsg("");
      }, 2000);
    } catch (error) {
      console.log("Error updating applied project:", error);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      class="modal fade"
      style={{width: "100%", height: "100%", backgroundColor: "transparent"}}
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Update Event Details
            </h5>
            <button
              type="button"
              onClick={() => setOpen(false)}
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div>
              <Form>
                <Form.Input
                  placeholder="Event Name"
                  label="Event Name"
                  readOnly="true"
                  value={Ename}
                />

                <br></br>
                <Form.Input
                  placeholder="Event Date"
                  label="Event Date"
                  type="datetime-local"
                  readOnly="true"
                  value={eventdate}
                />

                <br></br>
                <Form.Input
                  placeholder="Event Venue"
                  label="Event Venue"
                  type="text"
                  readOnly="true"
                  value={Evenue}
                />

                <br></br>

                <Form.Field
                  required={true}
                  control="select"
                  defaultValue={status}
                  onChange={(e) => setStatus(e.target.value)}
                  placeholder="Event status"
                  label="Event Status"
                >
                  <option value="">--Select--</option>
                  <option value="Present">Present</option>
                  <option value="Absent">Absent</option>
                </Form.Field>
                <Form.Input
                  defaultValue={certificate}
                  onChange={(e) => setCertificate(e.target.value)}
                  placeholder="Event Certificate"
                  label="Event Certificate"
                  required={true}
                />
              </Form>
              {msg && (
                <Alert
                  variant="success"
                  style={{fontSize: "11px", textAlign: "center"}}
                >
                  {msg}
                </Alert>
              )}
            </div>
          </div>
          <div class="modal-footer">
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              color="blue"
              type="submit"
              onClick={handleSubmit}
              class="btn btn-primary"
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalMEdit;
