import React, { useState, useEffect } from "react";
import { Loader, Icon, Button, Form } from "semantic-ui-react";
import { db } from "../../firebase-config";
import { useParams, useNavigate } from "react-router-dom";
import GoToTop from "../../GoToTop";
import {
  addDoc,
  updateDoc,
  doc,
  collection,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";

const initialState = {
  name: "",

  status: "",
};

const EditLeave = () => {
  const [data, setData] = useState(initialState);
  const { name, reason, todate, ref, enddate, status } = data;
  const [progress, setProgress] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    id && getSinglePhoto();
  }, [id]);

  const getSinglePhoto = async () => {
    const docRef = doc(db, "Leaves", id);
    const snapshot = await getDoc(docRef, "Leaves");
    if (snapshot.exists()) {
      setData({ ...snapshot.data() });
    }
  };

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let errors = {};

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let errors = validate();
    if (Object.keys(errors).length) return setErrors(errors);
    setIsSubmit(true);
    if (!id) {
      try {
        await addDoc(collection(db, "Leaves"), {
          ...data,
          statusPost: serverTimestamp(),
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        await updateDoc(doc(db, "Leaves", id), {
          ...data,
          statusPost: serverTimestamp(),
        });
      } catch (error) {
        console.log(error);
      }
    }

    navigate(-1);
  };

  return (
    <>
      <GoToTop />
      <main id="main" class="main">
        <div
          className="card"
          style={{ backgroundColor: "white", padding: "15px" }}
        >
          <h2 style={{ color: "#212A3E" }}>Update Leave Details</h2>
          <hr></hr>
          <div className="row">
            <div className="col-lg">
              {isSubmit ? (
                <Loader active inline="centered" size="huge" />
              ) : (
                <>
                  <Form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-lg-6 my-2">
                        <Form.Input
                          readOnly={true}
                          label="Name"
                          name="name"
                          placeholder="Name"
                          onChange={handleChange}
                          value={name}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <Form.Input
                          readOnly={true}
                          label="Reference-ID"
                          value={ref}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-8 my-2">
                        <Form.TextArea
                          label="Reason"
                          name="reason"
                          placeholder="Contact no."
                          onChange={handleChange}
                          value={reason}
                        ></Form.TextArea>
                      </div>

                      <div className="col-lg-2 my-2">
                        <Form.Input
                          label="Start Date"
                          name="startDate"
                          onChange={handleChange}
                          value={todate}
                        />
                      </div>

                      <div className="col-lg-2 my-2">
                        <Form.Input
                          label="End Date"
                          name="endDate"
                          onChange={handleChange}
                          value={enddate}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 my-2">
                        <Form.Field
                          label="Status"
                          style={{ height: "4.8vh" }}
                          name="status"
                          control="select"
                          value={status}
                          onChange={handleChange}
                        >
                          <option>Select status</option>
                          <option value="Pending">Pending</option>
                          <option value="Rejected">Reject</option>
                          <option value="Approved">Approve</option>
                        </Form.Field>
                      </div>
                    </div>

                    <br></br>

                    <Button
                      style={{ float: "right" }}
                      color="green"
                      type="submit"
                      size="small"
                      icon
                      labelPosition="left"
                    >
                      <Icon name="check" /> Update
                    </Button>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>{" "}
      </main>
    </>
  );
};

export default EditLeave;
